
import UiFooter from '@/components/UI/Footer/UiFooter'
import gtmViewPage from '~/mixins/gtm-view-page'

export default {
  name: 'LegalLayout',
  components: {
    UiFooter,
  },
  mixins: [gtmViewPage],
  data: () => ({
    // isAnswersComplete: false,
  }),
  computed: {},
  watch: {},
  beforeMount() {},
  methods: {},
}
