import { render, staticRenderFns } from "./PopupFeedbackForm.vue?vue&type=template&id=5fe1f47f&scoped=true&"
import script from "./PopupFeedbackForm.vue?vue&type=script&lang=js&"
export * from "./PopupFeedbackForm.vue?vue&type=script&lang=js&"
import style0 from "./PopupFeedbackForm.vue?vue&type=style&index=0&id=5fe1f47f&prod&lang=scss&scoped=true&"
import style1 from "./PopupFeedbackForm.vue?vue&type=style&index=1&id=5fe1f47f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe1f47f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/usr/src/app/components/UI/Input/UiInput.vue').default})
