/**
 * Берёт объект и возвращает новый объект без тех полей, значения которых есть в массиве discard
 * @param source - source object ({ [key: string]: any })
 * @param discard - set of values that should be discarded (any[])
 */
export const clean = (source, discard = ['', undefined]) => {
  const keys = Object.keys(source)
  return keys.reduce((prev, curr) => {
    return discard.includes(source[curr])
      ? prev
      : { ...prev, [curr]: source[curr] }
  }, {})
}
