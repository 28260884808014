import {collectAllowedTags, collectUtmTags, collectYMTags, mapUtmTagsToString,} from '~/helpers/UTMHelper'
import {userTypes} from "@/store/user";

const apiClientModulePath = `/sso/api/v1/utm`

const initialState = {
	utmTags: {},
	utmTagsAsString: '',
}

export const state = () => ({...initialState})

export const mutations = {
	setUtmTags(state, {utmTags}) {
		state.utmTags = utmTags
		state.utmTagsAsString = mapUtmTagsToString(utmTags)
	},
}

export const actions = {
	getUnauthorizedUserUtmTagsFromRoute({rootState, commit, dispatch}, {route, cookies}) {
		const cookiesUtm = cookies.get('utmTags');
		const tags = collectAllowedTags(route.query);

		const routeUtmTags = collectUtmTags(route.query);
		const cookiesUtmTags = collectUtmTags(cookiesUtm);

		if (cookiesUtm) {
			if (tags && Object.keys(tags).length) {
				const routeUtmTagsString = mapUtmTagsToString(routeUtmTags);

				if (routeUtmTagsString && routeUtmTagsString !== mapUtmTagsToString(cookiesUtmTags)) {
					commit('setUtmTags', {utmTags: tags});
					cookies.set('utmTags', tags);
				}
			} else {
				commit('setUtmTags', {utmTags: cookiesUtm});
			}
		} else if (tags && Object.keys(tags).length) {
			cookies.set('firstUtmTags', tags);
			cookies.set('utmTags', tags);
			commit('setUtmTags', {utmTags: tags});
		}
	},

	async getUtmTagsFromRoute({rootState, commit, dispatch}, {route, cookies}) {
		if (rootState.user.userType === userTypes.psychologist) {
			return
		}

		const YMtags = collectYMTags();
		let dbUtmTags = {};
		let response = await this.$axios.$get(`${apiClientModulePath}/last_utm_marks?client_id=${rootState.user.id}`);

		if (response && !response.length || (!response[0].last_utm)) {
			const firstUtmTags = cookies.get('firstUtmTags');
			if (firstUtmTags) {
				const newUtm = {...firstUtmTags, ...YMtags};

				await dispatch('sendUtmToServer', mapUtmTagsToString(newUtm));
				cookies.remove('firstUtmTags');
				dbUtmTags = collectUtmTags(firstUtmTags);
			}
		} else {
			const params = Object.fromEntries(new URLSearchParams(response[0].last_utm));
			dbUtmTags = collectUtmTags(params);
			cookies.remove('firstUtmTags');
		}

		const tags = collectAllowedTags(route.query);
		const routeUtmTags = collectUtmTags(route.query);

		if (routeUtmTags && Object.keys(routeUtmTags).length) {
			if (dbUtmTags && Object.keys(dbUtmTags).length) {
				const routeUtmTagsString = mapUtmTagsToString(routeUtmTags);
				if (!routeUtmTagsString || routeUtmTagsString === mapUtmTagsToString(dbUtmTags)) {
					return
				}
			}

			await commit('setUtmTags', {utmTags: tags});
			const newUtm = {...tags, ...YMtags};
			await dispatch('sendUtmToServer', mapUtmTagsToString(newUtm));
		} else {
			const cookiesUtm = cookies.get('utmTags');
			if (cookiesUtm) {
				await commit('setUtmTags', {utmTags: cookiesUtm});
				const newUtm = {...cookiesUtm, ...YMtags};
				await dispatch('sendUtmToServer', mapUtmTagsToString(newUtm));
			} else {
				if (YMtags && Object.keys(YMtags).length) {
					if (dbUtmTags && Object.keys(dbUtmTags).length) {
						await commit('setUtmTags', {utmTags: dbUtmTags});
						await dispatch('sendUtmToServer', mapUtmTagsToString({...dbUtmTags, ...YMtags}));
					} else {
						await dispatch('sendUtmToServer', mapUtmTagsToString(YMtags));
					}
				}
			}
		}

		cookies.remove('utmTags');
	},

	async sendUtmToServer({state, rootState}, utmTagsAsString) {
		const url = `${apiClientModulePath}/put_utm_marks`

		try {
			await this.$axios.$post(url, {
				utm_marks: utmTagsAsString || state.utmTagsAsString,
				client_id: rootState.user.id,
			})
		} catch (e) {
			throw new Error(`Put utm marks error: ${e.message}`)
		}
	},
}
