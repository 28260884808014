const apiModulePath = '/acquiring/api/v1'
const apiAcquiringPath = '/acquiring/api/v1'

const initialState = {
	paymentToken: null,
	paymentSessionId: null,
	confirmationUrl: '',
	paymentMethods: {
		others: [],
		primary: null
	}
}

export const state = () => ({...initialState})

export const mutations = {
	/**
	 *setPaymentToken
	 *
	 * @param {*} state
	 * @param {*} { paymentToken }
	 */
	setPaymentToken(state, {paymentToken}) {
		state.paymentToken = paymentToken
	},

	/**
	 *setConfirmationUrl
	 *
	 * @param {*} state
	 * @param {*} { confirmationUrl }
	 */
	setConfirmationUrl(state, {confirmationUrl}) {
		state.confirmationUrl = confirmationUrl
	},

	/**
	 *setPaymentToken
	 *
	 * @param {*} state
	 * @param {*} { paymentToken }
	 */
	setPaymentSessionId(state, {paymentSessionId}) {
		state.paymentSessionId = paymentSessionId
	},

	setPaymentMethods(state, paymentMethods) {
		state.paymentMethods.primary = paymentMethods?.primary;
		state.paymentMethods.others = paymentMethods?.others || [];
	},
}

export const actions = {
	/**
	 * createPayment
	 *
	 * @param {*} { commit }
	 * @param {*} { sessionId }
	 * @returns
	 */
	async createPayment({rootState, commit}, payload) {
		const url = `${apiModulePath}/payment/`
		const data = await this.$axios[payload.patch ? '$patch' : '$post'](url, {...payload, utm_marks: rootState.utm.utmTagsAsString,})
			.catch((error) => {
				throw error
			})

		if (
			data &&
			data.payment_details &&
			data.payment_details.confirmation &&
			data.payment_details.confirmation.confirmation_url
		) {
			const confirmationUrl = data.payment_details.confirmation.confirmation_url
			commit('setConfirmationUrl', {confirmationUrl})
			return confirmationUrl
		} else {
			return false
		}
	},

	async createPaymentWithSubscription({commit}, payload) {
		const url = `${apiModulePath}/client/market/subscriptions/${payload.subscription_id}/use_for_session`
		return await this.$axios.$post(url, payload)
	},

	async payInstant({rootState, commit}, payload) {
		const url = `${apiModulePath}/payment/instant`
		const data = await this.$axios.$post(url, payload)
			.catch((error) => {
				throw error
			})

		console.log(data);

		if (
			data &&
			data.payment_details &&
			data.payment_details.confirmation &&
			data.payment_details.confirmation.confirmation_url
		) {
			const confirmationUrl = data.payment_details.confirmation.confirmation_url
			commit('setConfirmationUrl', {confirmationUrl})
			return confirmationUrl
		} else {
			return false
		}
	},

	async getPaymentTokenBySessionId({commit}, sessionId) {
		const url = `${apiModulePath}/client/payment/${sessionId}`
		const data = await this.$axios.$get(url).catch((error) => {
			// eslint-disable-next-line
			console.error('getPaymentTokenBySessionId error', error)
			return false
		})

		if (data?.widget_token) {
			commit('setPaymentToken', {paymentToken: data?.widget_token})
			commit('setPaymentSessionId', {paymentSessionId: data?.session_id})
			return true
		}

		return false
	},

	/**
	 * checkPaymentId
	 * check key from payment service, gotten by return_url
	 *
	 * @param {*} store
	 * @param {*} paymentId
	 * @returns
	 */
	async checkPaymentId(store, paymentId) {
		if (!paymentId) {
			return false
		}
		const url = `${apiModulePath}/payment/${paymentId}/check_payment/`
		let error = null

		const data = await this.$axios
			.$post(url, {payment_id: paymentId})
			.catch((err) => {
				// eslint-disable-next-line
				console.error('checkPaymentId error', error)
				error = err
				return false
			})
		if (error) {
			return error
		} else if (data) {
			return data
		}
	},

	async payEducation(store, {data, discount}) {
		const url = `${apiModulePath}/payment/education`


		return await this.$axios
			.$post(url, data, discount ? {
				headers: {
					'X-Discount-Type': discount
				}
			} : null)
			.catch((error) => {
				return error.response.data
			})
	},

	async actionPurchaseProduct({rootState, commit}, productId) {
		const url = `${apiAcquiringPath}/client/market/orders/purchase-product`;
		const data = await this.$axios.$post(url, {product_id: productId})

		let confirmationUrl = null;
		if (data && data.payments && data.payments.length) {
			data.payments.every(
				v => {
					if (
						v.status === 'pending' &&
						v.payment_details &&
						v.payment_details.confirmation &&
						v.payment_details.confirmation.confirmation_url
					) {
						confirmationUrl = v.payment_details.confirmation.confirmation_url;
						return false;
					}
					return true;
				}
			)
		}
		if (confirmationUrl) {
			commit('setConfirmationUrl', {confirmationUrl})
		}
		return confirmationUrl
	},

	async actionDownloadCertificate(_, id) {
		return this.$axios.get(`${apiAcquiringPath}/client/market/certificates/${id}/download-pdf`, {responseType: 'blob'});
	},

	async actionGetPaymentMethods({commit}) {
		let {data} = await this.$axios.get(`${apiAcquiringPath}/client/payment/methods`);
		commit('setPaymentMethods', data);
	},

	async actionPaymentMethodMakePrimary({commit}, id) {
		await this.$axios.patch(`${apiAcquiringPath}/client/payment/methods/${id}/primary`);
	},

	async actionDeletePaymentMethod({commit}, id) {
		await this.$axios.delete(`${apiAcquiringPath}/client/payment/methods/${id}`);
	},

	async actionAddPaymentMethod(_, payload) {
		const {data} = await this.$axios.post(`${apiAcquiringPath}/client/payment/methods`, payload);
		return data;
	},
}
