export const actions = {
  async sendFeedback(ctx, data) {
    const url = `/feedbacks/api/v1/feedback/`
    const res = await this.$axios.$post(url, data)
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err)
      })
    return res;
  }
}
