
	import {IMaskComponent} from 'vue-imask'
	import UiIcon from '@/components/UI/Icon/UiIcon.vue'
	import regEx from '@/helpers/regEx'
	import punycode from '@/helpers/punycode'

	function applyPhoneMask(value) {
		const val = value.split(' ').join('').substr(0, 10)
		let newValue = ''
		for (let i = 0; i < val.length; i++) {
			if (i === 3 || i === 6 || i === 8) {
				newValue += ' '
			}
			newValue += val[i]
		}

		return {
			userText: newValue,
			userValue: val,
		}
	}

	function submitError() {
		this.emitUserValue({
			userValue: this.userText,
			id: this.params.id,
			isError: true,
			isFilling: this.isFilling,
		})
	}

	function sendUserValue() {
		if (this.params.type === 'email' && !regEx.email.test(this.emailToUnicode(this.userText))) {
			this.isError = true
			submitError.apply(this)
			return false
		}

		if (
			this.params.type === 'password' &&
			!regEx.password.test(this.userText?.trim().replace(/\s/g, '') || '')
		) {
			this.isError = true
			submitError.apply(this)
			return false
		}

		if (this.params.type === 'name' && !regEx.userName.test(this.userText)) {
			this.isError = true
			submitError.apply(this)
			return false
		}

		if (this.params.type === 'phone') {
			const currentValue = this.userText.split(' ').join('')
			const phonesValidate =
				currentValue.length &&
				this.phoneCode.code === '+7' &&
				!regEx.phone.test(currentValue)
			const phonesOtherValidate =
				currentValue.length &&
				this.phoneCode.code !== '+7' &&
				!regEx.phoneOthers.test(currentValue)
			if (phonesValidate || phonesOtherValidate) {
				// this.isError = true;
				submitError.apply(this)
				return false
			}
		}

		this.isError = false

		this.emitUserValue({
			userValue: this.userText,
			id: this.params.id,
			isError: this.isError,
			isFilling: this.isFilling,
		})

		return true
	}

	function handleSmsInput(value, oldValue) {
		if (!isNaN(value) && value.length <= 4 && !value.includes('e')) {
			return value
		}
		return oldValue
	}

	export default {
		components: {
			UiIcon,
			'imask-input': IMaskComponent,
		},
		props: {
			params: {
				type: Object,
				default: () => {
				},
			},
			phoneCode: {
				type: Object,
				default: () => {
				},
			},
			errorMessage: {
				type: String,
				default: '',
			},
			trim: Boolean,
			cyrillicOnly: Boolean,
		},
		data() {
			return {
				elKey: new Date().getTime(),
				isFilling: false,
				userText: this.params.userValue || '',
				isActive: this.params.isActive || false,
				isPasswordVisible: false,
				localType: this.params.type,
				isError: false,
				initialSmsState: ['_', '_', '_', '_'],
			}
		},
		computed: {
			updateSmsArr() {
				const currentVal = this.userText.split('')
				const updated = this.initialSmsState.map((item, i) => {
					if (currentVal[i]) {
						item = currentVal[i]
					}
					return item
				})
				return updated
			},
			getMaskPhone() {
				return this.phoneCode.code === '+7' ? '000 000 00 00' : '0000000000'
			},
			getPlaceholderPhone() {
				return this.phoneCode.code === '+7'
					? this.params.placeholder
					: '0000000000'
			},
		},
		watch: {
			// for programmatic update
			'params.userValue'(val) {
				this.userText = val
			},
			userText(val) {
				if (this.trim)
					this.userText = this.userText?.trim().replace(/\s/g, '') || ''
			},
		},
		mounted() {
			this.userText = this.params.userValue || ''
		},
		methods: {
			emitUserValue(data) {
				const userValue = this.trim
					? data.userValue?.trim().replace(/\s/g, '') || ''
					: data.userValue

				this.$emit('updateUserValue', {
					...data,
					userValue,
				})
			},
			emailToUnicode(email) {
				const emailPaths = email.split('@')
				if (emailPaths.length > 1) {
					return punycode.ToUnicode(emailPaths[0]) + '@' + punycode.ToUnicode(emailPaths[1])
				} else {
					return punycode.ToUnicode(emailPaths[0])
				}
			},
			onChange(e) {
				if (this.params.type === 'phone') {
					const updatedValue = applyPhoneMask(e.target.value)
					if (
						updatedValue.userValue.length &&
						!regEx.phone.test(updatedValue.userValue)
					) {
						// this.userText = updatedValue.userText;
						this.isError = true
						submitError.apply(this)
						return
					}

					this.userText = updatedValue.userText
					this.isError = false
					this.emitUserValue({
						userValue: updatedValue.userValue,
						id: this.params.id,
						isError: this.isError,
						isFilling: this.isFilling,
					})

					return
				}

				if (this.params.type === 'email' && this.userText && this.userText.length) {
					this.userText = this.emailToUnicode(this.userText)
				}

				sendUserValue.apply(this)

				// this.isError = false;
				this.emitUserValue({
					userValue: this.userText,
					id: this.params.id,
					isError: this.isError,
					isFilling: this.isFilling,
				})
			},
			togglePasswordVisibility() {
				this.isPasswordVisible = !this.isPasswordVisible
				this.localType = this.isPasswordVisible ? 'text' : this.params.type
			},
			onInput(e) {
				this.isFilling = true

				if (this.params.type === 'phone') {
					this.userText = this.$refs.inputEl.querySelector('input').value
					sendUserValue.apply(this)
					if (!this.userText.length) this.isFilling = false
					return
				}

				if (this.params.isSms) {
					const result = handleSmsInput(e.target.value, this.userText)
					this.userText = e.target.value = result
				}

				if (!e.target.value.length) {
					this.isFilling = false
				}
				this.userText = e.target.value
				sendUserValue.apply(this)
			},
			onFocus(e) {
				let el
				if (this.params.type === 'phone') {
					if (e.target.tagName !== 'INPUT') {
						el = this.$refs.inputEl.querySelector('input')
					} else {
						el = e.target
					}
				} else {
					el = this.$refs.inputEl
				}

				el.focus()
				this.isActive = true
				this.isFilling = !!el.value.length
				this.$emit('focus', {id: this.params.id})
			},
			onBlur(e) {
				this.isActive = false

				if (this.params.type === 'phone') {
					const updatedValue = applyPhoneMask(e.target.value)
					this.userText = updatedValue.userText
				}
			},
			onDelete() {
				this.userText = ''
				this.isError = false
				submitError.apply(this)
				this.isFilling = false

				if (this.params.type === 'password') {
					this.isPasswordVisible = false
				}

				this.$emit('onDelete')
			},
			onKeypress(evt) {
				if (this.cyrillicOnly) {
					this.onlyCyrillic(evt)
				}
			},
			onlyCyrillic(evt) {
				const regex = /^[а-яА-ЯёЁ\s-]+$/
				const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode)
				if (!regex.test(key)) {
					evt.preventDefault()
					return false
				}
			},
		},
	}
