import snakeToCamel from '~/helpers/snakeToCamel'

const apiModulePath = '/voximplant-aggregator/api/v1'

const initialState = {
	isVoxInited: false,
	isVoxConnected: false,
	isVoxLogged: false,
	isReconnecting: false,
	isScreenSharing: false,
	isRecallAttempts: false,
	isEndpointAdded: false,
	connectionError: '',
	micAccessGranted: false,
	devicesError: false,
	cameraError: '',
	connectionClosed: false,
	currentVoxUser: {
		account: '',
		applicationName: '',
		userName: '',
		token: '',
	},
	voxUsers: [],
	call: {
		connected: false,
		failed: false,
		error: false, // boolean | number (code)
		status: false, // boolean | CALL_STATUSES
		interlocutorMuted: false, // boolean
	},
	audioDeviceManager: null,
	audioInputDevices: null,
	audioOutputDevices: null,
	cameraDevices: null,
	deviceChangedCounter: 0,
	currentDevices: {
		'input': null,
		'output': null,
		'camera': null
	},
}

export const CALL_STATUSES = {
	started: 'started',
	active: 'active',
	ended: 'ended',
	ALERTING: 'ALERTING',
	CONNECTED: 'CONNECTED',
	ENDED: 'ENDED',
	PROGRESSING: 'PROGRESSING',
	UPDATING: 'UPDATING',
}

export const ACTIVE_STATUSES = [
	CALL_STATUSES.started,
	CALL_STATUSES.active,
	CALL_STATUSES.ALERTING,
	CALL_STATUSES.CONNECTED,
	CALL_STATUSES.PROGRESSING,
	CALL_STATUSES.UPDATING,
]
export const ENDED_STATUSES = [CALL_STATUSES.ended, CALL_STATUSES.ENDED]

export const state = () => ({...initialState})

export const mutations = {
	/**
	 *setIsInited
	 *
	 * @param {*} state
	 * @param {*} isInited
	 */
	setIsInited(state, isInited) {
		state.isVoxInited = isInited
	},

	/**
	 *setIsConnected
	 *
	 * @param {*} state
	 * @param {*} isConnected
	 */
	setIsConnected(state, isConnected) {
		state.isVoxConnected = isConnected
	},

	/**
	 *setIsLogged
	 *
	 * @param {*} state
	 * @param {*} isLogged
	 */
	setIsLogged(state, isLogged) {
		state.isVoxLogged = isLogged
	},

	/**
	 *setConnectionClosed
	 *
	 * @param {*} state
	 * @param {*} isClosed
	 */
	setConnectionClosed(state, isClosed) {
		state.connectionClosed = isClosed
	},

	/**
	 *setIsReconnecting
	 *
	 * @param {*} state
	 * @param {*} isReconnecting
	 */
	setIsReconnecting(state, isReconnecting) {
		state.isReconnecting = isReconnecting
	},

	/**
	 *setRecallAttempts
	 *
	 * @param {*} state
	 * @param {*} isRecallAttempts
	 */
	setRecallAttempts(state, isRecallAttempts) {
		state.isRecallAttempts = isRecallAttempts
	},

	/**
	 *setUser
	 *
	 * @param {*} state
	 * @param {*} user
	 */
	setUser(state, user) {
		state.currentVoxUser = user
	},

	/**
	 *setAudioDeviceManager
	 *
	 * @param {*} state
	 * @param {*} audioDeviceManager
	 */
	setAudioDeviceManager(state, audioDeviceManager) {
		state.audioDeviceManager = audioDeviceManager
	},

	/**
	 *setAudioInputDevices
	 *
	 * @param {*} stat
	 * @param {*} devices
	 */
	setAudioInputDevices(state, devices) {
		state.audioInputDevices = devices
	},


	setCurrentDevice(state, {type, device, showNotification = false}) {
		console.log(`setCurrentDevice ${type} : ${state.currentDevices[type]}`);
		if (!state.currentDevices[type]) {
			state.currentDevices[type] = device
		} else if (device && state.currentDevices[type].id !== device.id) {
			state.currentDevices[type] = device;
			//to show popup
			console.log(`New ${type} device ${device.id} assigned`);
			if(showNotification) {
				state.deviceChangedCounter = state.deviceChangedCounter + 1
			}
		}
	},

	/**
	 *setAudioOutputDevices
	 *
	 * @param {*} state
	 * @param {*} devices
	 */
	setAudioOutputDevices(state, devices) {
		state.audioOutputDevices = devices
	},

	setCurrentAudioOutputDevice(state, device) {
		state.currentAudioOutputDevice = device
	},

	/**
	 *setAudioOutputDevices
	 *
	 * @param {*} state
	 * @param {*} devices
	 */
	setCameraDevices(state, devices) {
		state.cameraDevices = devices
	},

	/**
	 *updateDeviceChangedCounter
	 *
	 * @param {*} state
	 */
	updateDeviceChangedCounter(state) {
		state.deviceChangedCounter = state.deviceChangedCounter + 1
	},

	/**
	 *setUserToUsers
	 *
	 * @param {*} state
	 * @param {*} user
	 */
	setUserToUsers(state, user) {
		const isInStore = !!state.voxUsers.filter((u) => u.id === user.id).length
		if (isInStore) {
			const index = state.voxUsers.map((u) => u.id).indexOf(user.id)
			state.voxUsers[index] = user
		} else {
			state.voxUsers = [...state.voxUsers, user]
		}
	},

	/**
	 *updateUser
	 *
	 * @param {*} state
	 * @param {*} user
	 */
	updateUser(state, user) {
		state.currentVoxUser = {...state.currentVoxUser, ...user}
	},

	/**
	 *setCall
	 *
	 * @param {*} state
	 * @param {*} call
	 */
	setCall(state, call) {
		state.call = call
	},

	/**
	 *setCallStatus
	 *
	 * @param {*} state
	 * @param {*} status
	 */
	setCallStatus(state, status) {
		state.call.status = status
	},

	/**
	 *updateCall
	 *
	 * @param {*} state
	 * @param {*} call
	 */
	updateCall(state, call) {
		state.call = {...state.call, error: null, ...call}
	},

	/**
	 *setMicAccessGranted
	 *
	 * @param {*} state
	 * @param {*} access
	 */
	setMicAccessGranted(state, access) {
		state.micAccessGranted = access
	},

	/**
	 *setDevicesError
	 *
	 * @param {*} state
	 * @param {*} isError
	 */
	setDevicesError(state, isError) {
		state.devicesError = isError
	},
	/**
	 *setCameraError
	 *
	 * @param {*} state
	 * @param {*} message
	 */
	setCameraError(state, message) {
		state.cameraError = message
	},
	/**
	 * setIsScreenSharing
	 *
	 * @param {*} state
	 * @param {*} isScreenSharing
	 */
	setIsScreenSharing(state, isScreenSharing) {
		state.isScreenSharing = isScreenSharing
	},

	setIsEndpointAdded(state, isEndpointAdded) {
		state.isEndpointAdded = isEndpointAdded
	},
}

export const actions = {
	/**
	 *getVoxData
	 *
	 * @param {*} { commit }
	 * @returns
	 */
	async getVoxData({commit}) {
		const url = `${apiModulePath}/voximplant_aggregator/vox_data`
		let error = null
		const data = await this.$axios.get(url).catch((err) => {
			error = err
		})
		if (!data && error) {
			return error
		} else {
			const camelUserData = {}
			const user = data.data
			Object.keys(user).forEach((option) => {
				camelUserData[snakeToCamel(option)] = user[option]
			})
			commit('updateUser', camelUserData)
			return data
		}
	},

	/**
	 *createVoxToken
	 *
	 * @param {*} { rootState, commit }
	 * @returns
	 */
	async createVoxToken({state, rootState, commit}, key) {
		const url = `${apiModulePath}/voximplant_aggregator/create_token`
		let error = null
		const data = await this.$axios.post(url, {key}).catch((err) => {
			error = err
		})
		if (!data && error) {
			return error
		} else {
			if (data.data.token) {
				const userData = state.currentVoxUser
				const token = data.data.token
				const newUserData = {...userData, token}
				commit('updateUser', newUserData)
			}
			return data
		}
	},
}

export const getters = {
	/**
	 *getVoxUserById
	 *
	 * @param {*} state
	 * @param {*} id
	 * @returns
	 */
	getVoxUserById(state) {
		return (id) => {
			return state.voxUsers.filter((u) => u.id === id)[0]
		}
	},
}
