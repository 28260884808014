export const state = () => ({
  dictionary: [],
})

export const mutations = {
  setDictionary(state, data) {
    state.dictionary = data
  },
}

export const actions = {
  async fetchDictionary(ctx) {
    const url = `/rubricator/api/v1/psychologist/dictionary/`
    const res = await this.$axios.$get(url)
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err)
      })
    if ( res ) ctx.commit('setDictionary', res.data)
  }
}
