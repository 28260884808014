export const tzData = [
  {
    id: 1,
    name: 'Калининград, UTC+2',
    text: 'Калининград, UTC+2',
    value: 'Europe/Kaliningrad',
  },
  {
    id: 2,
    name: 'Москва, Санкт-Петербург, UTC+3',
    text: 'Москва, Санкт-Петербург, UTC+3',
    value: 'Europe/Moscow',
  },
  { id: 3, name: 'Киров, UTC+3', text: 'Киров, UTC+3', value: 'Europe/Kirov' },
  {
    id: 4,
    name: 'Волгоград, UTC+3',
    text: 'Волгоград, UTC+3',
    value: 'Europe/Volgograd',
  },
  {
    id: 5,
    name: 'Астрахань, UTC+4',
    text: 'Астрахань, UTC+4',
    value: 'Europe/Astrakhan',
  },
  {
    id: 6,
    name: 'Саратов, UTC+4',
    text: 'Саратов, UTC+4',
    value: 'Europe/Saratov',
  },
  {
    id: 7,
    name: 'Ульяновск, UTC+4',
    text: 'Ульяновск, UTC+4',
    value: 'Europe/Ulyanovsk',
  },
  {
    id: 8,
    name: 'Самара, UTC+4',
    text: 'Самара, UTC+4',
    value: 'Europe/Samara',
  },
  {
    id: 9,
    name: 'Екатеринбург, UTC+5',
    text: 'Екатеринбург, UTC+5',
    value: 'Asia/Yekaterinburg',
  },
  { id: 10, name: 'Омск, UTC+6', text: 'Омск, UTC+6', value: 'Asia/Omsk' },
  {
    id: 11,
    name: 'Новосибирск, UTC+7',
    text: 'Новосибирск, UTC+7',
    value: 'Asia/Novosibirsk',
  },
  {
    id: 12,
    name: 'Барнаул, UTC+7',
    text: 'Барнаул, UTC+7',
    value: 'Asia/Barnaul',
  },
  { id: 13, name: 'Томск, UTC+7', text: 'Томск, UTC+7', value: 'Asia/Tomsk' },
  {
    id: 14,
    name: 'Новокузнецк, UTC+7',
    text: 'Новокузнецк, UTC+7',
    value: 'Asia/Novokuznetsk',
  },
  {
    id: 15,
    name: 'Красноярск, UTC+7',
    text: 'Красноярск, UTC+7',
    value: 'Asia/Krasnoyarsk',
  },
  {
    id: 16,
    name: 'Иркутск, UTC+8',
    text: 'Иркутск, UTC+8',
    value: 'Asia/Irkutsk',
  },
  { id: 17, name: 'Чита, UTC+9', text: 'Чита, UTC+9', value: 'Asia/Chita' },
  {
    id: 18,
    name: 'Якутск, UTC+9',
    text: 'Якутск, UTC+9',
    value: 'Asia/Yakutsk',
  },
  {
    id: 19,
    name: 'Хандыга, UTC+9',
    text: 'Хандыга, UTC+9',
    value: 'Asia/Khandyga',
  },
  {
    id: 20,
    name: 'Владивосток, UTC+10',
    text: 'Владивосток, UTC+10',
    value: 'Asia/Vladivostok',
  },
  {
    id: 21,
    name: 'Усть-Нера, UTC+10',
    text: 'Усть-Нера, UTC+10',
    value: 'Asia/Ust-Nera',
  },
  {
    id: 22,
    name: 'Магадан, UTC+11',
    text: 'Магадан, UTC+11',
    value: 'Asia/Magadan',
  },
  {
    id: 23,
    name: 'Сахалин, UTC+11',
    text: 'Сахалин, UTC+11',
    value: 'Asia/Sakhalin',
  },
  {
    id: 24,
    name: 'Среднеколымск, UTC+11',
    text: 'Среднеколымск, UTC+11',
    value: 'Asia/Srednekolymsk',
  },
  {
    id: 25,
    name: 'Камчатка, UTC+12',
    text: 'Камчатка, UTC+12',
    value: 'Asia/Kamchatka',
  },
  {
    id: 26,
    name: 'Анадырь, UTC+12',
    text: 'Анадырь, UTC+12',
    value: 'Asia/Anadyr',
  },
]

export const getTzName = (val) => {
  const t = tzData.find((item) => item.value === val)
  return t ? t.name : val
}

export const getTzByName = (val) => {
  const t = tzData.find((item) => item.name === val)
  return t ? t.value : val
}


export const tzValueFromName = [
  {
    id: 1,
    value: '+2',
    name: 'Europe/Kaliningrad',
  },
  {
    id: 2,
    value: '+3',
    name: 'Europe/Moscow',
  },
  {
    id: 3,
    value: '+3',
    name: 'Europe/Kirov'
  },
  {
    id: 4,
    value: '+3',
    name: 'Europe/Volgograd',
  },
  {
    id: 5,
    value: '+4',
    name: 'Europe/Astrakhan',
  },
  {
    id: 6,
    value: '+4',
    name: 'Europe/Saratov',
  },
  {
    id: 7,
    value: '+4',
    name: 'Europe/Ulyanovsk',
  },
  {
    id: 8,
    value: '+4',
    name: 'Europe/Samara',
  },
  {
    id: 9,
    value: '+5',
    name: 'Asia/Yekaterinburg',
  },
  {
    id: 10,
    value: '+6',
    name: 'Asia/Omsk'
  },
  {
    id: 11,
    value: '+7',
    name: 'Asia/Novosibirsk',
  },
  {
    id: 12,
    value: '+7',
    name: 'Asia/Barnaul',
  },
  {
    id: 13,
    value: '+7',
    name: 'Asia/Tomsk'
  },
  {
    id: 14,
    value: '+7',
    name: 'Asia/Novokuznetsk',
  },
  {
    id: 15,
    value: '+7',
    name: 'Asia/Krasnoyarsk',
  },
  {
    id: 16,
    value: '+8',
    name: 'Asia/Irkutsk',
  },
  {
    id: 17,
    value: '+9',
    name: 'Asia/Chita'
  },
  {
    id: 18,
    value: '+9',
    name: 'Asia/Yakutsk',
  },
  {
    id: 19,
    value: '+9',
    name: 'Asia/Khandyga',
  },
  {
    id: 20,
    value: '+10',
    name: 'Asia/Vladivostok',
  },
  {
    id: 21,
    value: '+10',
    name: 'Asia/Ust-Nera',
  },
  {
    id: 22,
    value: '+11',
    name: 'Asia/Magadan',
  },
  {
    id: 23,
    value: '+11',
    name: 'Asia/Sakhalin',
  },
  {
    id: 24,
    value: '+11',
    name: 'Asia/Srednekolymsk',
  },
  {
    id: 25,
    value: '+12',
    name: 'Asia/Kamchatka',
  },
  {
    id: 26,
    value: '+12',
    name: 'Asia/Anadyr',
  },
]
