

const attachmentUrlFromDsn = (dsn, eventId) => {
  const { host, path, projectId, port, protocol, publicKey } = dsn;
  return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
    path !== '' ? `/${path}` : ''
  }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${publicKey}&sentry_version=7&sentry_client=custom-javascript`;
}

export const attachmentProcessor = (dsn, event, data) => {
  try {
    const endpoint = attachmentUrlFromDsn(dsn, event.event_id);
    const formData = new FormData();
    formData.append(
      'my-attachment',
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      }),
      'logs.json'
    );
    fetch(endpoint, {
      method: 'POST',
      body: formData,
    }).catch((ex) => {
      // we have to catch this otherwise it throws an infinite loop in Sentry
      console.error(ex);
    });
    return event;
  } catch (ex) {
    console.error(ex);
  }
}
