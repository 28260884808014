export default ({form, questions}) => {
  function getAnswer(question, allAnswer) {
    const rawAnswer = allAnswer[question.question_slug]
    if (question.multiple) {
      const answerSet = new Set(rawAnswer)
      return question.options.filter(({ id }) => answerSet.has(id))
    }
    return question.options.find(({ id }) => id === rawAnswer)
  }

  return questions.map((question) => {
    return {
      required: question.required,
      multiple: question.multiple,
      required_group: question.required_group,
      question_slug: question.question_slug,
      answer: getAnswer(question, form),
    }
  })
}
