
	import BasePopup from './BasePopup.vue'
	import UiButton from '@/components/ui-kit/buttons/Button'

	export default {
		components: {BasePopup, UiButton},
		props: {
			isVisible: {type: Boolean, default: false},
			title: {type: String, default: ''},
		},
		methods: {
			onClose(e) {
				this.$emit('closePopup', e)
			},
		},
	}
