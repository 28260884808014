import formatApplicationAnswers from "@/helpers/formatApplicationAnswers";

const apiModulePath = `/rubricator/api/v1`

const initialState = {
	question: [],
	clientAnswers: {},
}

export const state = () => ({
	...initialState,
})

export const mutations = {
	setClientAnswers(state, answers) {
		state.clientAnswers = answers
	},
}

export const actions = {
	/**
	 *
	 * @param {*}
	 */
	async getClientQuestion(_) {
		const url = `${apiModulePath}/client/client_question/?with_dicts=true`
		const response = await this.$axios.$get(url)
		return response
	},

	/**
	 *
	 * @param {*} form result answer
	 * @param {*} baseForm inner model for the send request
	 */
	async sendClientAnswer(_, {form, baseForm}) {
		const model = baseForm.map((item) => {
			return {
				required: item.required,
				multiple: item.multiple,
				required_group: item.required_group,
				question_slug: item.question_slug,
				answer: form[item.question_slug]?.value
					? form[item.question_slug].value
					: item.multiple
						? form[item.question_slug][item.order_number]
						: form[item.question_slug],
			}
		})

		const url = `${apiModulePath}/client/client_application/`
		const response = await this.$axios.$post(url, {
			complete: true,
			results: model,
		})
		return response
	},

	/**
	 *
	 * @param {*} form result answer
	 * @param {*} baseForm inner model for the send request
	 */
	async validateClientAnswer(_, {form, questions, isCompleted}) {
		const model = formatApplicationAnswers({questions, form})

		const url = `${apiModulePath}/client/client_application/validate`
		const response = await this.$axios.$post(url, {complete: true, results: model})
		return response
	},

	/**
	 *
	 * @param {*} form result answer
	 * @param {*} baseForm inner model for the send request
	 */
	async updateClientAnswer(_, {answers, isCompleted}) {
		const url = `${apiModulePath}/client/client_application/`
		const response = isCompleted
			? await this.$axios.$patch(url, {complete: true, results: answers})
			: await this.$axios.$post(url, {complete: true, results: answers})
		return response
	},

	/**
	 * Get actual answer for the selection
	 */
	async getClientAnswer({commit}, is_public) {
		try {
			const url = `${apiModulePath}/${is_public ? 'public' : 'client'}/client_application/`
			const response = await this.$axios.$get(url).catch((error) => {
				console.error('getClientAnswer ', error)
				return {complete: false, id: '', results: []}
			})

			commit('setClientAnswers', response)
			return response
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			console.error(err, err.message)
			throw err
		}
	},
}
