
	import ClickOutside from 'vue-click-outside';
	import UiIcon from '@/components/UI/Icon/UiIcon.vue';
	import UiMenuLeft from '@/components/UI/MenuLeftNew/UiMenuLeft.vue';

	import utmMixin from "@/mixins/utm";

	export default {
		components: {UiIcon, UiMenuLeft},
		mixins: [utmMixin],
		directives: {
			ClickOutside,
		},
		data() {
			return {
				isOpen: false,
			}
		},
		methods: {
			toggleMenu() {
				this.isOpen = !this.isOpen
			},
		},
	}
