
	import utmMixin from "@/mixins/utm";
	import guardMixin from '@/mixins/guard';
	import {mapActions} from 'vuex';

	export default {
		name: "login",
		mixins: [guardMixin, utmMixin],
		methods: {
			...mapActions({
				getUnauthorizedUserUtmTags: 'utm/getUnauthorizedUserUtmTagsFromRoute'
			}),
		},
		created() {
			this.getUnauthorizedUserUtmTags({route: this.$route, cookies: this.$cookies});
		},
	}
