const BASE_URL = process.env.apiBase

// calendar
export const API_CALENDAR_PSY = `${BASE_URL}/calendar/api/v1/psychologist/calendar`;
// sessions
export const FROM_SESSION_CLI = '/sessions/api/v1/client/session';
export const FROM_SESSION_PSY = 'sessions/api/v1/psychologist/session';
export const API_SESSION_CLI = `${BASE_URL}/${FROM_SESSION_CLI}`;
export const API_SESSION_PSY = `${BASE_URL}/${FROM_SESSION_PSY}`;
// settings
export const API_PROFILE = `${BASE_URL}/sso/api/v1/profile`;
export const API_SETTINGS = `${API_PROFILE}/settings`;

export function createCalendarPsy(token, data) {
	const url = `${API_CALENDAR_PSY}`
	return fetch(url, {
		mode: 'cors',
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(data),
	})
}

export function getCalendarPsy(token) {
	const url = `${API_CALENDAR_PSY}/?limit=100`
	return fetch(url, {
		mode: 'cors',
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export function deleteCalendarItemPsy(token, id) {
	const url = `${API_CALENDAR_PSY}/${id}`
	return fetch(url, {
		mode: 'cors',
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export function patchCalendarItemPsy(token, id, data) {
	const url = `${API_CALENDAR_PSY}/${id}` // a26e9a71-0db4-405b-9ad9-b2b5de256808
	return fetch(url, {
		mode: 'cors',
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(data),
	})
}

export const getErrMessage = async (res) => {
	const msg = await res.json()

	let error = msg.detail || msg.error_message

	if (msg.field_errors) {
		msg.field_errors.forEach((err) => {
			error += `\n\r${err.field} - ${err.message}`
		})
	}

	if (error.includes('earlier')) {
		const hours = Number(error.replace(/\D/g, ''))
		if (hours) {
			error = `Нельзя записаться или перенести сессию на слот до которого осталось менее ${hours} ${hours % 10 === 1 ? 'часа' : 'часов'}. Пожалуйста, выберите другой день или время.`
		} else {
			error = 'Нельзя записаться или перенести сессию на слот до которого осталось менее часа. Пожалуйста, выберите другой день или время.'
		}
	}
	if (error.includes('Slot is busy')) {
		error = 'Слот занят'
	}

	return error
}

export function getCalendarCli(token, psyId) {
	const API = `${BASE_URL}/calendar/api/v1/client/calendar`
	const url = `${API}/?psychologist_id=${psyId}&page=0&page_size=100`
	return fetch(url, {
		mode: 'cors',
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export function getSessionCli(token) {
	const url = `${API_SESSION_CLI}?page=0&page_size=100` // &session_status=EXPECTED
	return fetch(url, {
		mode: 'cors',
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export function cancelSessionCli(token, id) {
	const url = `${API_SESSION_CLI}/cancellation/${id}`
	return fetch(url, {
		mode: 'cors',
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export function getApplicationCli(token, id) {
	const url = `${BASE_URL}/rubricator/api/v1/client_application/${id}`
	return fetch(url, {
		mode: 'cors',
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export function bookSlot(token, payload) {
	const url = `${BASE_URL}/sessions/api/v1/client/session`
	return fetch(url, {
		mode: 'cors',
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	})
}

export function updateSettingsCli(token, payload) {
	const url = `${API_SETTINGS}/client`
	return fetch(url, {
		mode: 'cors',
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	})
}

export function updateSettingsPsy(token, payload) {
	const url = `${API_SETTINGS}/psychologist`
	return fetch(url, {
		mode: 'cors',
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	})
}

export function changePassPsy(token, payload) {
	const url = `${API_SETTINGS}/psychologist/password`
	return fetch(url, {
		mode: 'cors',
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	})
}

export function updateSettingsPhone(token, number) {
	const url = `${API_PROFILE}/phone-number`
	return fetch(url, {
		mode: 'cors',
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			phone_number: number,
		}),
	})
}

export function verifyPhone(token, payload) {
	const url = `${API_PROFILE}/verify-phone`
	return fetch(url, {
		mode: 'cors',
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	})
}
