
	import BasePopup from './BasePopup.vue'
	import UiIcon from '@/components/UI/Icon/UiIcon.vue'
	import UiButton from '@/components/ui-kit/buttons/Button'

	export default {
		name: 'PopupError',
		components: {BasePopup, UiIcon, UiButton},
		props: {
			title: {
				type: String,
				default: 'Ошибка при загрузке данных',
			},
			isVisible: {
				type: Boolean,
				default: false,
			},
			description: {
				type: String,
				default: 'Попробуйте повторить еще раз',
			},
			errorList: {
				type: Array,
				default: () => [],
			},
		},
	}
