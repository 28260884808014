const apiModulePath = `/calendar/api/v1`

const initialState = {
	test: '',
	calendar: [],
	integrations: {"apple":null,"google":null,"yandex":null,"custom":null}
}

export const state = () => ({
	...initialState,
})


export const mutations = {
	setCalendar(state, data) {
		state.calendar = data
	},

	setIntegrations(state, data) {
		state.integrations = data
	},
}

export const actions = {
	/**
	 *
	 * @param _
	 * @param {*} params
	 */
	async find({commit}, params) {
		const {to, from, pageSize, tz} = params
		const url = `${apiModulePath}/psychologist/calendar/`
		const response = await this.$axios.$get(url, {
			params: {
				to,
				from,
				page_size: pageSize,
				tz,
			},
		})
		commit('setCalendar', response.results)
		return response
	},

	/**
	 * Create new sessions
	 * @param _
	 * @param {*} payload {date session}
	 * @returns
	 */
	async createSession(_, payload) {
		const url = `${apiModulePath}/psychologist/calendar/`
		return await this.$axios.$post(url, payload)
	},

	/**
	 * Remove
	 * @returns
	 */
	async deleteCalendarItemPsy(_, calendarId) {
		const url = `${apiModulePath}/psychologist/calendar/${calendarId}`
		return await this.$axios.$delete(url)
	},

	/**
	 * Update
	 * @returns
	 */
	async updateCalendarItemPsy(_, {calendarId, date}) {
		const url = `${apiModulePath}/psychologist/calendar/${calendarId}`
		return await this.$axios.$patch(url, {date})
	},

	/**
	 * Canceled session
	 * @param _
	 * @param {*} calendarId calendar_id session;
	 */
	async infoCalendar(_, calendarId) {
		const url = `${apiModulePath}/psychologist/calendar/${calendarId}`
		return await this.$axios.$get(url)
	},

	/**
	 * Get calendar notes for the client
	 * @param _
	 * @param params = {id: Identificator psychologist, is_public}
	 */
	async getCalendarNotesCli({commit}, params) {
		try {
			const {id, is_public} = params;
			const url = `${apiModulePath}/${is_public ? 'public': 'client'}/calendar/?psychologist_id=${id}&page=0&page_size=100`
			const response = await this.$axios.$get(url)
			commit('setCalendar', response.results)
			return response
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}


			console.error(err, err.message)
		}
	},

	async getIntegrations({commit}, userType) {
		const res = await this.$axios.$get(`${apiModulePath}/${userType}/calendar/integrations`)
		commit('setIntegrations', res);
	},
	async deleteIntegration({commit},  payload = {}) {
		console.log(payload);
		await this.$axios.$delete(`${apiModulePath}/${payload.userType}/calendar/integrations/${payload.id}`)
	},
	async addIntegration({commit}, payload = {}) {
		await this.$axios.$post(`${apiModulePath}/${payload.userType}/calendar/integrations/caldav/${payload.type}`, payload.data)
	},
	async getGoogleIntegrationRedirectUrl({commit}, userType) {
		return  await this.$axios.$get(`${apiModulePath}/${userType}/calendar/integrations/oauth/google?frontend_develop_mode=${process.env.NODE_ENV !== 'production'}`)
	},
	async addGoogleIntegration({commit}, {code, state}) {
		return  await this.$axios.$get(`${apiModulePath}/public/calendar/integrations/oauth/google?state=${state}&code=${code}}`)
	},
}
