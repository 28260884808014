import {serializeSearchParams} from '@/helpers/network'
import {returnUniqueValues} from '@/helpers/array'

export const state = () => ({
	psychologist: [],
})

export const mutations = {
	setPsychologist(state, data) {
		state.psychologist = data
	},
}

export const actions = {
	async fetchPsychologist({commit}, data) {
		try {
			const params = serializeSearchParams({
				...data,
				psychologist_ids: returnUniqueValues(data?.psychologist_ids),
			})
			const url = `/rubricator/api/v1/client/psychologist_application/`
			const res = await this.$axios.$get(url, {params})
			if (res) commit('setPsychologist', res.results)
			return res.results
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			throw new Error(err)
		}
	},

	async fetchPsychologistById(ctx, id) {
		const url = `/rubricator/api/v1/client/psychologist_application/?psychologist_id=${id}`
		const res = await this.$axios.$get(url).catch((err) => {
			// eslint-disable-next-line
			console.error(err)
		})
		if (res) ctx.commit('setPsychologist', res.results)
		return res
	},
}
