export const state = () => ({})

export const mutations = {}

export const actions = {
	async nuxtServerInit({ commit, dispatch }, { req }) {
		try {
			await dispatch('auth/setCookiesAuth');
		} catch (e) {
			console.log('Error:', e)
		}
	}
}
