const apiModulePath = `/rubricator/api/v1`
const defaultFilterState = {
	therapy_method_id__in: [],
	gender: 'any',
	age: {
		ge: 18,
		le: 100,
	},
};

const initialState = {
	selectedGroup: null,
	selectedSpecialistType: null,
	psychologists: [],
	groups: [],
	pageSize: 12,
	page: 1,
	isTeenagerGroupsShowed: false,
	filter: defaultFilterState
}

export const state = () => ({
	...initialState,
})

export const getters = {
	teenagersGroups(state) {
		return state.groups.filter(group => {
			return group.age_category_type === 'teenagers';
		})
	},
	adultsGroups(state) {
		return state.groups.filter(group => {
			return group.age_category_type === 'adults';
		})
	},
	isDefaultFilters (state) {
		return state.filter === defaultFilterState;
	}
}

export const mutations = {
	setPsychologists(state, payload) {
		state.psychologists = (payload.response ?? []).map((item) => {
			let price = 0;
			try {
				price = parseInt(item.cost_category?.name)
			} catch (e) {
				console.log(e);

				if (this.$sentry) {
					this.$sentry.captureException(e)
				}
			}

			return {
				...item,
				price: price,
				first_session_price: Math.round(price * 0.8),
				isPsychologist: !payload.specialization_type || payload.specialization_type  === 'PSYCHOLOGY',
				specialization_type: payload.specialization_type || 'PSYCHOLOGY'
			}
		})
	},
	setGroups(state, groups) {
		state.groups = groups;
	},
	setSelectedGroup(state, group) {
		state.selectedGroup = group;
	},
	setSelectedSpecialistType(state, specialistType) {
		state.selectedSpecialistType = specialistType;
	},
	setPage(state, page) {
		state.page = page;
	},
	setIsTeenagerGroupsShowed(state, isTeenagerGroupsShowed) {
		state.isTeenagerGroupsShowed = isTeenagerGroupsShowed;
	},
	setFilter(state, filter) {
		state.filter = filter;
	},

	setDefaultFilter(state) {
		state.filter = defaultFilterState;
	}
}

export const actions = {
	async fetchRubricator({commit}, {force, specialization_type}) {
		const response = await this.$axios.$get(`${apiModulePath}/client/rubricator/v2${specialization_type ? '?specialization_type='+specialization_type: ''}`
			, force ? {
				headers: {
					'X-Force-Disable-Cache': true
				}
			} : null)

		commit('setPsychologists', {response, specialization_type});
		commit('setPage', 1);
	},

	async fetchRubricatorByFilter({commit}, {filter, idSearch}) {
		const response = await this.$axios.$post(`${apiModulePath}/client/rubricator/v2/filter?search_id=${idSearch}`, filter)

		commit('setPsychologists', {response});
		commit('setPage', 1);
	},

	async fetchRubricatorByGroupId({commit}, {group, is_public}) {
		const response = await this.$axios.$get(`${apiModulePath}/${is_public ? 'public' : 'client'}/rubricator/v2/groups?issue_group_id__in=${group.id}`)

		commit('setSelectedGroup', group);
		commit('setPsychologists', {response});
		commit('setPage', 1);
	},

	async fetchPublicRubricatorByApplication({commit}, application) {
		const url = `${apiModulePath}/public/rubricator/v2`
		const response = await this.$axios.$post(url, {complete: true, results: application})

		commit('setPsychologists', {response});
		commit('setPage', 1);
	},

	async fetchPublicRubricatorByFilter({commit}, {filter, idSearch}) {
		const response = await this.$axios.$post(`${apiModulePath}/public/rubricator/v2/filter?search_id=${idSearch}`, filter)

		commit('setPsychologists', {response});
		commit('setPage', 1);
	},

	async fetchGroups({commit}) {
		const response = await this.$axios.$get(`${apiModulePath}/public/issues/groups`);
		commit('setGroups', response);
	},

	async getTopicsByClientRequest({commit}, {is_public, request_text} = {}) {
		return await this.$axios.$get(`${apiModulePath}/${is_public ? 'public' : 'client'}/integrations/gpt/yandex/issues?request_text=${request_text}`)
	},

	async sendRequestToSearch({commit}, payload) {
		const {is_public, data} = payload;
		const response = await this.$axios.$post(`/amocrm-service/api/${is_public ? 'public' : 'client'}/leads/manual-search`, data)
	},
}
