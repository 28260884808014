export const state = () => ({
  psychos: [],
})

export const mutations = {
  setTitularPsychos(state, data) {
    state.psychos = data
  },
}

export const actions = {
  async fetchPsychos(ctx) {
    const url = '/sso/api/v1/main_page/titular-psychologist?page=0&page_size=10'
    const res = await this.$axios.$get(url)
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err)
      })
    if (res) ctx.commit('setTitularPsychos', res.results)
  }
}
