/* eslint-disable */
export default {
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&\{\}\"])[A-Za-z\d@$!#%*?&\{\}\"]{8,}$/,
  email:
    /^[a-zA-Zа-яА-Я_\d][-a-zA-Zа-яА-Я0-9_\.\d]*\@[a-zA-Zа-яА-Я\d][-a-zA-Zа-яА-Я\.\d]*\.[a-zA-Zа-яА-Я]{2,4}$/,
  phone: /^[0-9]{10}$/,
  phoneOthers: /^[0-9]{8,10}$/,
  sms: /^[0-9]{4}$/,
  userName: /^[а-яА-Я]{5,30}$/,
}
//test@test.ru
//1234567Q1!
