// export default function ({ $axios, store }, inject) {
//   const getToken = () => {
//     return () => store.state.auth.authTokens.token
//   }
//   // Create a custom axios instance
//   const axiosBearer = $axios.create({
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + getToken(),
//     },
//   })

//   // Inject to context as $axiosBearer
//   inject('axiosBearer', axiosBearer)
// }

const noRetryUrls = ['/sso/api/v1/auth/refresh-token']
const noBearerUrls = ['/sso/api/v1/auth/refresh-token']

export default function ({$axios, $cookies, redirect, store, app}) {
	$axios.onRequest((config) => {
		const {token, refreshToken} = store.state.auth.authTokens
		const url = config.url
		if (token && !noBearerUrls.includes(url)) {
			config.headers.Authorization = `Bearer ${token}`
		} else if (!token && !refreshToken) {
			store
				.dispatch('auth/setCookiesAuth')
				.then((res) => {
					const restoredToken = store.state.auth.authTokens.token
					if (restoredToken) {
						config.headers.Authorization = `Bearer ${restoredToken}`
					}
				})
				.catch((err) => {
					return err
				})
		}
		return config
	})

	$axios.onError(async (error) => {
		const originalRequest = error.config
		const code = parseInt(error.response && error.response.status)
		const isAuthenticated = store.state.auth.isAuth

		const {refreshToken} = store.state.auth.authTokens

		if (!refreshToken && (code === 401 || code === 403)
			&& !originalRequest.url.includes('/sso/api/v1/auth/verify-email')
			&& !originalRequest.url.includes('/sso/api/v1/login/verify-code')
			&& !originalRequest.url.includes('/sso/api/v1/auth')
			&& !originalRequest.url.includes('/sso/api/v1/auth')
			&& !originalRequest.url.includes('/sso/api/v1/oauth/sign-in')) {
			console.log('>>> code ', code)
			const originalUrl = originalRequest.url
			console.log('>>> originalUrl', originalUrl)
			console.log('>>> isAuthenticated', isAuthenticated)

			// for blocked phones
			if (originalUrl === '/sso/api/v1/profile/phone-number') return error
			if (originalUrl === '/sso/api/v1/psychologist-login/sign-in') throw error

			if (
				(originalUrl && noRetryUrls.includes(originalUrl)) ||
				!isAuthenticated
			) {
				console.log('>> redirect')
				await store.dispatch('auth/cleanAuthData')
				console.log(
					'>>> store.state.auth.isAuth after auth/cleanAuthData',
					store.state.auth.isAuth
				)
				if (app.router.history.current.path !== '/') {
					redirect('/login')
				}
				throw error
			} else {
				originalRequest._retry = true

				store
					.dispatch('auth/refreshTokenAction')
					.then(() => {
						return $axios(originalRequest)
					})
					.catch((error) => {
						console.warn('>>> auth/refreshTokenAction error', error)
						store.dispatch('auth/cleanAuthData')
						redirect('/')
					})
			}
		} else {
			throw error
		}
	})
}
