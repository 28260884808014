
	import BasePopup from './BasePopup.vue'
	import UiIcon from '@/components/UI/Icon/UiIcon.vue'
	import UiButton from '@/components/ui-kit/buttons/Button'

	export default {
		components: {BasePopup, UiIcon, UiButton},
		props: ['title', 'isVisible'],
		methods: {
			onClose(e) {
				this.$emit('closeNotifyPopup', e)
			},
		},
	}
