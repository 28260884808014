import {mapGetters} from 'vuex'

export default {
	computed: {
		...mapGetters('user', ['gtmUserPage']),

	},
	methods: {
		/**
		 * Отправляем событие GAEvent в GTM
		 * @param {string} buttonName - eventContent
		 * @param {string} context - eventContext
		 * @param {string} label - eventLabel
		 * @param {string} action - eventAction
		 * @param {string} category - eventCategory
		 */
		gtmButtonClick(
			buttonName = '',
			context = 'menu-header',
			label = 'button',
			action = 'click',
			category = 'mainPage',
			additional = {}
		) {
			try {
				const data = {
					event: 'GAEvent',
					eventCategory: category,
					eventAction: action,
					eventLabel: label,
					eventContent: buttonName,
					eventContext: context,
					...additional,
				}

				this.$gtm.push({
					...data,
					eventCallback() {
						if (process.env.NODE_ENV !== 'production') {
							// eslint-disable-next-line no-console
							console.log(
								`ALL tags gtmButtonClick have now fired: ${JSON.stringify(data)}`
							)
						}
					},
				})
			} catch (e) {
				if (this.$sentry) {
					this.$sentry.captureException(e)
				}
			}
		},
	},
}
