import Vue from 'vue';
import format from "date-fns/format";
import locale from "date-fns/locale/ru";

const currency = function (number) {
	if (number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ₽';
	}
	return '0 ₽'
	// return number ? number + ' ₽' : '0 ₽';
}

const ageDeclination = function (val) {
	const age = +val;
	const titles = [' год', ' года', ' лет'];
	const cases = [2, 0, 1, 1, 1, 2];
	return `${age} ${titles[(age % 100 > 4 && age % 100 < 20) ? 2 : cases[(age % 10 < 5) ? age % 10 : 5]]}`;
}

const qualificationDeclination = function (val) {
	return `${val} проф. курс${val > 1 && val < 5 ? 'а': val >= 5 ? 'ов': '' }`;
}

const shortSurname = function (surname) {
	return surname && surname.length > 1 ? surname[0] + '.' : '';
}

const fullDate = function (date) {
	if (!date)
		return '';

	return format(typeof date === 'string' || date instanceof String ? new Date(date) : date, 'p, d MMMM, EEEE', {locale});
}

const dateTime = function (date) {
	if (!date)
		return '';

	return format(typeof date === 'string' || date instanceof String ? new Date(date) : date, 'd MMMM p', {locale});
}

const time = function (date) {
	if (!date)
		return '';

	return format(typeof date === 'string' || date instanceof String ? new Date(date) : date, 'H:mm', {locale});
}

const shortDate = function (date) {
	if (!date)
		return '';

	return format(typeof date === 'string' || date instanceof String ? new Date(date) : date, 'd MMMM', {locale});
}

const date = function (date) {
	return date ? format(new Date(date), 'dd.MM.yyyy', {locale}) : ''
}

const sessionsDisplayName = function (number) {
	return `${number} сесси${number === 1 ? 'ю' : number >= 5 ? 'й' : 'и'}`
}

const filters = {
	currency,
	ageDeclination,
	qualificationDeclination,
	shortSurname,
	fullDate,
	dateTime,
	time,
	shortDate,
	date,
	sessionsDisplayName
};

Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));
