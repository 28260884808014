export const NAV_FOR_CLIENT = [
	{
		id: 'client-main',
		title: 'Главная',
		icon: 'icons24/home',
		href: 'client-main'
	},
	{
		id: 'client-sessions',
		title: 'Мои сессии',
		icon: 'icons24/calendar_light',
		href: 'client-sessions'
	},
	{
		id: 'client-find-psychologist',
		title: 'Подбор психолога',
		icon: 'icons24/search_alt_light',
		href: 'search-psychologist',
	},
	{
		id: 'shop',
		title: 'Магазин',
		icon: 'icons24/bag_alt_light',
		href: 'shop',
		subItems: [
			{
				id: 'shop-purchases',
				title: 'Мои покупки',
				icon: 'icons24/purchases',
				href: 'shop-purchases',
			},
			{
				id: 'shop-subscriptions',
				title: 'Абонементы',
				icon: 'icons24/subscriptions',
				href: 'shop-subscriptions',
			},
			{
				id: 'shop-certificates',
				title: 'Сертификаты',
				icon: 'icons24/certificates',
				href: 'shop-certificates',
			}
		]
	},
	{
		id: 'client-discount',
		title: 'Акции',
		icon: 'icons24/discount',
		href: 'client-discount'
	},
	{
		id: 'client-settings',
		title: 'Мой профиль',
		icon: 'icons24/user_cicrle_light',
		href: 'client-profile-settings'
	},
];

export const NAV_FOR_B2B_SAMOLET_CLIENT = [
	{
		id: 'client-main',
		title: 'Главная',
		icon: 'icons24/home',
		href: 'client-main'
	},
	{
		id: 'client-sessions',
		title: 'Мои сессии',
		icon: 'icons24/calendar_light',
		href: 'client-sessions'
	},
	{
		id: 'client-find-psychologist',
		title: 'Подбор психолога',
		icon: 'icons24/search_alt_light',
		href: 'search-psychologist',
	},
	{
		id: 'client-find-others',
		title: 'Другие специалисты',
		icon: 'icons24/others',
		href: 'search-others',
	},
	{
		id: 'shop',
		title: 'Магазин',
		icon: 'icons24/bag_alt_light',
		href: 'shop',
		subItems: [
			{
				id: 'shop-purchases',
				title: 'Мои покупки',
				icon: 'icons24/purchases',
				href: 'shop-purchases',
			},
			{
				id: 'shop-certificates',
				title: 'Сертификаты',
				icon: 'icons24/certificates',
				href: 'shop-certificates',
			}
		]
	},
	{
		id: 'client-discount',
		title: 'Акции',
		icon: 'icons24/discount',
		href: 'client-discount'
	},
	{
		id: 'client-settings',
		title: 'Мой профиль',
		icon: 'icons24/user_cicrle_light',
		href: 'client-profile-settings'
	},
];

export const NAV_FOR_B2B_CLIENT = [
	{
		id: 'client-main',
		title: 'Главная',
		icon: 'icons24/home',
		href: 'client-main'
	},
	{
		id: 'client-sessions',
		title: 'Мои сессии',
		icon: 'icons24/calendar_light',
		href: 'client-sessions'
	},
	{
		id: 'client-find-psychologist',
		title: 'Подбор психолога',
		icon: 'icons24/search_alt_light',
		href: 'search-psychologist',
	},
	{
		id: 'shop',
		title: 'Магазин',
		icon: 'icons24/bag_alt_light',
		href: 'shop',
		subItems: [
			{
				id: 'shop-purchases',
				title: 'Мои покупки',
				icon: 'icons24/purchases',
				href: 'shop-purchases',
			},
			{
				id: 'shop-certificates',
				title: 'Сертификаты',
				icon: 'icons24/certificates',
				href: 'shop-certificates',
			}
		]
	},
	{
		id: 'client-discount',
		title: 'Акции',
		icon: 'icons24/discount',
		href: 'client-discount'
	},
	{
		id: 'client-settings',
		title: 'Мой профиль',
		icon: 'icons24/user_cicrle_light',
		href: 'client-profile-settings'
	},
];

export const NAV_FOR_PSY = [
	// {
	// 	id: 'psychologist-dashboard',
	// 	title: 'Дашборд',
	// 	icon: 'icons24/dashboard',
	// 	href: 'psychologist-dashboard'
	// },
	{
		id: 'psychologist-sessions',
		title: 'Мои сессии',
		icon: 'icons24/sessions',
		href: 'psychologist-sessions'
	},
	{
		id: 'psychologist-calendar',
		title: 'Календарь',
		icon: 'icons24/calendar_light',
		href: 'psychologist-calendar'
	},
	{
		id: 'psychologist-profile-main',
		title: 'Профиль',
		icon: 'icons24/user_cicrle_light',
		href: 'psychologist-profile-main'
	},
	{
		id: 'psychologist-settings',
		title: 'Настройки',
		icon: 'icons24/setting_line_light',
		href: 'psychologist-profile-settings'
	},
];

export const NAV_FOR_ANONYMOUS = [
	{
		id: 'login',
		title: 'Войти',
		icon: 'icons24/login',
		href: 'login',
		separate: 'both'
	},
	{
		id: 'client-find-psychologist',
		title: 'Подбор психолога',
		icon: 'icons24/search_alt_light',
		href: 'search-psychologist',
	},
	{
		id: 'shop',
		title: 'Магазин',
		icon: 'icons24/bag_alt_light',
		href: 'shop',
		subItems: [
			{
				id: 'shop-certificates',
				title: 'Сертификаты',
				icon: 'icons24/certificates',
				href: 'shop-certificates',
			}
		]
	},
];
