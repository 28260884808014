import sortBy from 'just-sort-by';

const apiModulePath = `/rubricator/api/v1`

const initialState = {
	listAllDictionary: [],
}

export const state = () => ({
	...initialState,
})

export const mutations = {
	setAllListDictionary(state, data) {
		state.listAllDictionary = data
	},
}

const getDictionaryType = (state, dictionaryName) => {
	if (state.listAllDictionary) {
		let dictionary = null
		state.listAllDictionary.map((item) => {
			if (item.name === dictionaryName) {
				dictionary = item
			}
			return {
				items: [],
			}
		})

		return {
			...dictionary,
			items: (dictionary.items || []).map((item) => {
				return {
					...item,
					value: item.id,
					text: item.name,
				}
			}),
		}
	}
}

export const getters = {
	getTherapyMethods(state) {
		if (state.listAllDictionary.length === 0)
			return {
				items: [],
			}
		return getDictionaryType(state, 'Методы терапии')
	},

	getFormatsMethods(state) {
		if (state.listAllDictionary.length === 0)
			return {
				items: [],
			}

		return getDictionaryType(state, 'Форматы терапии')
	},
	getAgeCategory(state) {
		if (state.listAllDictionary.length === 0)
			return {
				items: [],
			}
		const ageCategory = getDictionaryType(state, 'Возрастные категории')
		return {
			...ageCategory,
			items: ageCategory.items.map((item) => ({
				...item,
				text: item.name,
			})),
		}
	},

	getNoWorkIssues(state) {
		if (state.listAllDictionary.length === 0)
			return {
				items: [],
			}

		const noWorkIssues = {
			items: [],
		}
		state.listAllDictionary.forEach((item) => {
			switch (item.name) {
				case 'Состояния':
				case 'Отношения с окружающими':
				case 'Работа/учёба':
				case "Травматические события":
				case "Отношения с собой":
				case "Здоровье и тело":
				case "Работа и учёба": {
					item.items.forEach((el) => {
						if (state.psychologistInfo) {
							state.psychologistInfo.no_work_issues.forEach((method) => {
								noWorkIssues.items.push({
									...el,
									value: el.id,
									text: el.name,
									selected: el.id === method.id,
								})
							})
						} else {
							noWorkIssues.items.push({
								...el,
								value: el.id,
								text: el.name,
							})
						}
					})
					break
				}
			}
		})

		noWorkIssues.items = sortBy(noWorkIssues.items, item => item.name)
		return noWorkIssues
	},
	getPreferredIssues(state) {
		if (state.listAllDictionary.length === 0)
			return {
				items: [],
			}
		const preferredIssues = {
			items: [],
		}
		state.listAllDictionary.forEach((item) => {
			switch (item.name) {
				case 'Состояния':
				case 'Отношения с окружающими':
				case 'Работа/учёба':
				case "Травматические события":
				case "Отношения с собой":
				case "Здоровье и тело":
				case "Работа и учёба": {
					item.items.forEach((el) => {
						if (state.psychologistInfo) {
							state.psychologistInfo.preferred_issues.forEach((method) => {
								preferredIssues.items.push({
									...el,
									value: el.id,
									text: el.name,
									selected: el.id === method.id,
								})
							})
						} else {
							preferredIssues.items.push({
								...el,
								value: el.id,
								text: el.name,
							})
						}
					})
					break
				}
			}
		})

		preferredIssues.items = sortBy(preferredIssues.items, item => item.name)

		return preferredIssues;
	},
}

export const actions = {
	/**
	 * function for the get all list dictionary.
	 * @param {*}
	 */
	async getDictionaries({commit}, user_type) {
		const url = `${apiModulePath}/${user_type}/dictionary/`
		const response = await this.$axios.$get(url)

		commit('setAllListDictionary', response)
		return response
	},
}
