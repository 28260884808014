
	import UiIcon from '@/components/UI/Icon/UiIcon.vue'
	import gtmButtonClick from '@/mixins/gtm-button-click';

	export default {
		mixins: [gtmButtonClick],
		components: {UiIcon},
		props: {
			item: {
				type: Object,
				default: () => {}
			},
		},
		data() {
			return {
				expanded: true
			}
		},
        computed: {
            classes() {
                let classes = 'menu-item '

                if (this.item?.separate === 'both') {
                    classes += 'border-y-[1px] border-border mb-32 px-16 py-12 -mx-16 first:-mt-16'
                }

                return classes
            }
        },
		methods: {
			onMenuItemClick(item) {
				this.gtmButtonClick(
					item.title,
					'left-menu',
					'button',
					'click',
					this.gtmUserPage
				);
			},
		}
	}
