
import UiIcon from '../Icon/UiIcon.vue'

export default {
  components: { UiIcon },
  props: {
    options: { type: Object, default: () => {} },
  },
  data() {
    return {
      isError: !!this.options.errorMessage,
      isFilling: false,
      localValue: this.options.userValue,
    }
  },
  watch: {
    localValue() {
      this.$emit('updateUserValue', this.localValue)
    },
  },
  methods: {
    onFocus() {
      if (this.options.isDisabled || this.options.isReadOnly) return
      this.isFilling = true
      this.$emit('focus')
    },
    deleteUserText() {
      this.isFilling = false
      this.$emit('updateUserValue', '')
      this.localValue = ''
    },
    onBlur() {
      this.isFilling = false
    },
    onChange() {
      this.$emit('updateUserValue', this.localValue)
    },
  },
}
