import { render, staticRenderFns } from "./PopupDefaultTimezone.vue?vue&type=template&id=65b41edd&scoped=true&"
import script from "./PopupDefaultTimezone.vue?vue&type=script&lang=js&"
export * from "./PopupDefaultTimezone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b41edd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/usr/src/app/components/UI/Icon/UiIcon.vue').default,UiSelect: require('/usr/src/app/components/UI/Select/UiSelect.vue').default})
