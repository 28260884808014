
	import UiIcon from "@/components/UI/Icon/UiIcon";

	export default {
		name: "ButtonLink",
		components: {UiIcon},
		props: {
			icon: String,
			iconPosition: {
				type: String,
				default: 'left',
				validator(value) {
					return ['left', 'right'].includes(value)
				},
			},
			size: {
				type: String,
				default: 'default',
				validator(value) {
					return ['small', 'default'].includes(value)
				},
			}
		}
	}
