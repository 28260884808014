import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
	timeout: 10000,
};


Vue.use(Toast, options);
