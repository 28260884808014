
	import utmMixin from "@/mixins/utm";
	import gtmButtonClick from '@/mixins/gtm-button-click';

	export default {
		mixins: [utmMixin, gtmButtonClick],
		data () {
			return {
				year: new Date().getFullYear()
			}
		},
		methods: {
			sendAnalytics(socialName) {
				this.gtmButtonClick(
					'social',
					socialName,
					'button',
					'click',
					'clientPage'
				);
			}
		}
	}
