const utmTags = [
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_content',
	'utm_term',
	'utm_registration',
	'roistat',
	'roistat_visit',
	'roistat_referrer',
	'roistat_pos',
	'_openstat',
	'yclid',
	'gclid',
	'click_id',
	'admitad_uid'
];

export default ({app, $cookies}) => {
	app.router.beforeEach((to, from, next) => {
			let diff = {};

			utmTags.forEach(tag => {
				if (from?.fullPath?.includes(tag) && !to.fullPath.includes(tag)) {
					diff[tag] = from.query[tag]
				}
			})

			if (Object.keys(diff).length) {
				app.router.push({...to, query: {...to.query, ...diff}})
				return
			}

			const redirect_url = $cookies.get('redirect_url');

			if (redirect_url && redirect_url.includes(to.path)) {
				$cookies.remove('redirect_url')
			}

			next()
		}
	)
}
