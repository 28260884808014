
	import {mapActions, mapState} from 'vuex';
	import gtmViewPage from '~/mixins/gtm-view-page';
	import guardMixin from '@/mixins/guard';

	export default {
		name: 'SimpleLayout',
		mixins: [gtmViewPage, guardMixin],
		computed: {
			...mapState('auth', ['isAuth']),
		},
		watch: {
			isAuth: {
				handler() {
					if (this.isAuth) {
						this.getUtmTags({route: this.$route, cookies: this.$cookies});
					}
				}
			}
		},
		methods: {
			...mapActions({
				getUtmTags: 'utm/getUtmTagsFromRoute',
			}),
		}
	}
