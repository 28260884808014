export const SESSION_STATUSES = {
	EXPECTED: 'EXPECTED',
	IN_PROGRESS: 'IN_PROGRESS',
	FINISHED: 'FINISHED',
}


export const DEFAULT_COST_CATEGORY_IDS = [
	'a0ff4727-2cb7-4929-a8da-cc25881e7a2c',
	'79d3925e-d0a7-4f5e-8967-4da9644a2f52',
	'ab7f1549-ec28-44db-8354-1d4df8735964',
	'd42fe620-3bf7-42dc-974f-17b2e0f684d7'
]
