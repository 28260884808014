import {collectAllowedTags} from '~/helpers/UTMHelper'

export default {
	computed: {
		utmParams () {
			const utms = collectAllowedTags(this.$router.currentRoute.query)

			if (utms) {
				const searchParams = new URLSearchParams(utms);
				return  searchParams.toString();
			} else {
				return '';
			}
		},
		baseUrlWithParams() {
			return '/?' + this.utmParams;
		},
	},
}
