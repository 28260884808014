import { clean } from '@/helpers/object'

/**
 * Преобразовывает query параметры, где массив отправляется в виде /?array_name=value_1&array_name=value_2
 * (по дефолту аксиос отправляет /?array_name[]=value_1&array_name[]=value_2)
 * @param params - объект c параметрами запроса
 */
export const serializeSearchParams = (params) => {
  const cleanParams = clean(params, ['', null, undefined])
  const res = new URLSearchParams()
  Object.keys(cleanParams).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((i) => res.append(key, i))
    } else {
      res.append(key, params[key])
    }
  })
  return res
}
