
	import {mapActions, mapState} from 'vuex';

	import gtmViewPage from '~/mixins/gtm-view-page'
	import guardMixin from '@/mixins/guard'

	import UiLoaderComponent from '@/components/UI/Loader/UiLoaderComponent.vue';
	import UiFooter from '@/components/UI/Footer/UiFooter'
	import MobileHeader from '@/components/Client/Common/MobileHeader'
	import PopupError from '@/components/Client/Popup/PopupError'
	import PopupNotify from "@/components/Client/Popup/PopupNotify";
	import PopupDefaultTimezone from "@/components/Client/Popup/PopupDefaultTimezone";
	import {userTypes} from "@/store/user";

	export default {
		name: 'DefaultLayout',
		components: {
			PopupDefaultTimezone,
			PopupNotify,
			UiFooter,
			MobileHeader,
			PopupError,
			UiLoaderComponent
		},

		mixins: [guardMixin, gtmViewPage],

		data: () => ({
			isOffline: false,
			isVisibleConfirmationEmailPopup: false,
			isVisibleErrorPopup: false,
			// isVisibleTimezonePopup: false,
			actionError: null,
			tokenInterval: null,
		}),
		computed: {
			...mapState({
				user: (s) => s.user,
			}),
			...mapState('auth', ['isAuth', 'authTokens', 'parsedToken', 'initialized']),
		},
		watch: {
			authTokens: {
				handler() {
					this.setTokenRefreshInterval()
				},
				deep: true
			},
			isAuth: {
				async handler() {
					if (this.isAuth) {
						// await this.getUtmTags({route: this.$route, cookies: this.$cookies});

						if (this.$store.state.user.userType === userTypes.client) {
							await this.getClientPromocodes();
						}
					}
				}
			}
		},
		methods: {
			...mapActions({
				storeSetCookiesAuth: 'auth/setCookiesAuth',
				storeRefreshToken: 'auth/refreshTokenAction',
				getUtmTags: 'utm/getUtmTagsFromRoute',
				getUnauthorizedUserUtmTags: 'utm/getUnauthorizedUserUtmTagsFromRoute',
			}),
			setTokenRefreshInterval() {
				if (this.authTokens.token) {
					const diff = this.parsedToken.exp - this.parsedToken.iat;
					if (!this.tokenInterval && diff) {

						this.tokenInterval = setInterval(() => {
							this.storeRefreshToken()
						}, (diff / 2) * 1000);
					}
				} else if (this.tokenInterval) {
					clearInterval(this.tokenInterval);
					this.tokenInterval = null;
				}
			},

			changeInternetStatus(value) {
				this.isOffline = value;
			}
		},

		beforeDestroy() {
			clearInterval(this.tokenInterval);
			this.tokenInterval = null;
			window.removeEventListener('online', () => {});
			window.removeEventListener('offline', () => {});
		},
		async created() {
			try {
				await this.storeSetCookiesAuth();
				await this.$store.dispatch("globalSettings/fetchGlobalSettings")
				if (process.client) {
					window.addEventListener('online', () => this.changeInternetStatus(false));
					window.addEventListener('offline', () => this.changeInternetStatus(true));
				}
				if (this.isAuth) {
					await this.getUtmTags({route: this.$route, cookies: this.$cookies});
				} else {
					this.getUnauthorizedUserUtmTags({route: this.$route, cookies: this.$cookies});
				}

				const userData = this.$store.state.user
				if (userData.emailOnVerification && !this.$route.path.includes('email')) {
					this.isVisibleConfirmationEmailPopup = true;
				}
			} catch (e) {
				if (this.$sentry) {
					this.$sentry.captureException(e)
				}
			}
		},

		// async mounted() {
		// 	if (!this.isAuth) {
		// 		const default_timezone = this.$cookies.get('default_timezone');
		//
		// 		if (!default_timezone) {
		// 			this.isVisibleTimezonePopup = true;
		// 			this.$nextTick(() => {
		// 				this.$modal.show('default-timezone-modal');
		// 			})
		// 		} else {
		// 			this.$store.commit('user/setTimezone', default_timezone);
		// 		}
		// 	}
		// }
	}
