
	import UiIcon from '@/components/UI/Icon/UiIcon.vue'
	import gtmButtonClick from '@/mixins/gtm-button-click'

	export default {
		components: {
			UiIcon,
		},
		mixins: [gtmButtonClick],
		props: {
			position: {
				type: String,
				default: 'Пользователь',
			},
			user: {
				type: Object,
				default: () => {
				},
			},
		},
		computed: {
			isAuth() {
				return this.$store.state.auth.isAuth
			},
		},
	}
