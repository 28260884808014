const apiAcquiringPath = '/acquiring/api/v1'

export const state = () => ({
	products: {
		subscription: [],
		certificate: []
	}
})

export const mutations = {
	setProducts(state, {product_type, products}) {
		state.products[product_type] = products
	},
}

export const actions = {
	async getProducts({commit}, {product_type}) {
		const url = `${apiAcquiringPath}/client/market/products?product_type=${product_type}`;
		const res = await this.$axios.$get(url)

		commit('setProducts', {
			product_type,
			products: res.items
		})
	},
	async getProduct({commit}, id) {
		const url = `${apiAcquiringPath}/client/market/products/${id}`;
		return  await this.$axios.$get(url)
	},
	async activateCertificate({commit}, certificate_code) {
		const url = `${apiAcquiringPath}/client/market/certificates/activate-using-code`;
		return  await this.$axios.$post(url, {
			certificate_code
		})
	},
}
