/**
 * Удаляет дубликаты из массива примитивных значений
 * @param { Array } items
 */
export const returnUniqueValues = (items) => {
  if (!Array.isArray(items)) return null

  const uniqueItems = []
  items.forEach((element) => {
    if (!uniqueItems.includes(element)) {
      uniqueItems.push(element)
    }
  })
  return uniqueItems
}
