const allowedTags = ['utm', 'openstat', 'yclid', 'gclid', 'click_id', 'admitad_uid'];
const utmTags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'click_id', 'admitad_uid'];

export const collectUtmTags = (query) => {
	const tags = {};

	if(query) {
		Object.keys(query).forEach((param) => {
			utmTags.forEach(tag => {
				if (param.includes(tag)) {
					tags[param] = query[param];
				}
			})
		})
	}

	return tags;
}

export const collectAllowedTags = (query) => {
	const tags = {};

	if(query) {
		Object.keys(query).forEach((param) => {
			allowedTags.forEach(tag => {
				if (param.includes(tag)) {
					tags[param] = query[param];
				}
			})
		})
	}

	return tags;
}

export const collectYMTags = () => {
	const tags = {};
	let _ym_uid = '';
	let _ym_counter = process.env.YA_COUNTER;

	try {
		if (_ym_counter && window) {
			_ym_uid = window[`yaCounter${_ym_counter}`].getClientID();
		}
	} catch (e) {
		console.log(e);
	}

	if (_ym_counter && _ym_uid) {
		tags['_ym_counter'] = _ym_counter;
		tags['_ym_uid'] = _ym_uid;
	}

	return tags;
}

export const mapUtmTagsToString = (tags) =>
	Object.keys(tags)
		.map((key) => `${key}=${tags[key]}`)
		.join('&')
