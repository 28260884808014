
	export default {
		name: "BaseButton",
		props: {
			type: {
				type: String,
				validator(value) {
					return ['button', 'submit', 'reset'].includes(value)
				},
				default: 'button'
			},
			disabled: {type: Boolean},
			color: {
				validator(value) {
					return ['primary', 'secondary', 'secondary-negative', 'tertiary', 'ghost', 'outline', 'outline-orange', 'white', 'white-orange'].includes(value)
				},
				default: 'primary'
			}
		},
		computed: {
			classes() {
				const classes = [];
				classes.push(`btn--${this.color}`);
				return classes;
			}
		}
	}
