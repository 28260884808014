
	import BasePopup from './BasePopup.vue'
	import UiButton from '@/components/ui-kit/buttons/Button'
	import UiInput from '@/components/UI/Input/UiInput.vue'
	import UiTextarea from '@/components/UI/Textarea/UiTexarea.vue'

	export default {
		components: {BasePopup, UiButton, UiInput, UiTextarea},
		props: {
			isVisible: {type: Boolean, default: false},
			messageOk: {type: String, default: ''},
			messageErr: {type: String, default: ''},
		},
		data() {
			return {
				description:
					'Если у вас возникли вопросы, проблемы в работе с системой или комментарии, напишите их. В ближайшее время мы ответим на них по электронной почте',
				inputsData: [
					{
						id: 1,
						placeholder: 'Ваш e-mail',
						userValue: '',
						label: 'Введите ваш e-mail',
						type: 'email',
						isRequired: true,
						isFilling: false,
						isValid: false,
					},
					{
						id: 2,
						placeholder: 'Ваше имя',
						userValue: '',
						label: 'Ваше имя',
						type: 'name',
						isValid: true,
						isRequired: false,
						isFilling: false,
						isDisabled: true,
					},
				],
				textarea: {
					placeholder: 'Ваш вопрос',
					name: 'Ваш вопрос',
					userValue: '',
					isRequired: true,
				},
				disableButton: false,
				isEmailDelete: false,
			}
		},
		computed: {
			inputUserName() {
				const inputData = this.inputsData[1]
				const nameFromStore = this.$store.state.user.username
				if (!inputData.userValue) {
					inputData.userValue = nameFromStore
				}
				if (inputData.userValue) inputData.isFilling = true
				return inputData
			},
			inputUserEmail() {
				return this.inputsData[0]
			},
			isInputsValid() {
				const isEmailValid = this.inputUserEmail.isValid
				const isNameValid = this.inputUserName.isFilling
				const isTextAreaValid = this.textarea.userValue.trim() !== ''

				return isEmailValid && isNameValid && isTextAreaValid
			},
			userEmailFromStore() {
				return this.$store.state.user.email
			},
		},
		watch: {
			isVisible() {
				if (this.isVisible) this.fillEmailFromStore()
			},
		},
		methods: {
			onClose(e) {
				this.$emit('closeFeedbackPopup', e)
			},
			onSubmit() {
				if (this.isInputsValid) {
					const data = {
						email: this.inputUserEmail.userValue,
						name: this.inputUserName.userValue,
						feedback: this.textarea.userValue,
					};
					this.disableButton = true
					this.$emit('submitFeedbackForm', data);
				}
			},
			clearFields() {
				this.inputUserEmail.userValue = this.inputUserName.userValue = this.textarea.userValue = ''
				this.disableButton = false
			},
			updateUserValue(e) {
				const currentInput = this.inputsData.find((opt) => opt.id === e.id)
				currentInput.userValue = e.userValue
				currentInput.isValid = !e.isError
				currentInput.isFilling = e.isFilling
			},
			updateTextArea(e) {
				this.textarea.userValue = e
			},
			fillEmailFromStore() {
				this.$nextTick(() => {
					if (this.userEmailFromStore) {
						this.inputUserEmail.userValue = this.userEmailFromStore
						this.inputUserEmail.isValid = true
					}
				})
			},
		},
	}
