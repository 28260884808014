
import InlineSvg from 'vue-inline-svg'
export default {
  components: {
    InlineSvg,
  },
  props: {
    name: { type: String, default: '' },
    path: { type: String, default: '' },
  },
  data() {
    return {}
  },
  computed: {
    ico() {
      return this.name ? `./icons/${this.name}.svg` : this.path
    },
  },
}
