function createConstants(context, inject) {
	// Create a custom axios instance
	const constVal = {
		clientLoginPath: '/login',
		psychologistLoginPath: '/login',
		mainClientPrivatePath: '/client/main',
		clientPrivatePaths: [
			'/client/sessions',
			'/client/sessions/',
			'/client/profile/settings',
			'/client/profile/settings/',
		],
		mainPsychologistPrivatePath: '/psychologist/sessions',
		psychologistPrivatePaths: [
			'/psychologist/sessions',
			'/psychologist/sessions/',
			'/psychologist/profile/settings',
			'/psychologist/profile/settings/',
			'/client/sessions/end/recomendation',
		],
		whitelistedPaths: [],
		registrationPaths: [
			'/client/email',
			'/client/email/',
			'/Client/email',
			'/Client/email/',
		],
	}

	// Inject to context as $axiosBearer
	inject('const', {...constVal})
}

export default createConstants
