export const state = () => ({
	files: [],
	uploaded_files: [],
	isLoadingFiles: false,
})

export const mutations = {
	addFile(state, file) {
		if (
			!state.files.length ||
			!state.files.find((f) => f.name === file[0].name)
		) {
			state.files.push(file[0])
		}
	},
	setUploadedFiles(state, id) {
		state.uploaded_files.push(id)
	},
	removeFile(state, file) {
		state.files = state.files.filter((f) => f.name !== file.name)
	},
	removeUploadedFile(state, idx) {
		state.uploaded_files.splice(idx, 1)
	},
	clearFiles(state) {
		state.files = []
	},
	clearUploadedFiles(state) {
		state.uploaded_files = []
	},
	setLoadingFiles(state, payload) {
		state.isLoadingFiles = !!payload;
	},
}

export const actions = {
	async uploadHomeWorkFile(ctx, data) {
		const url = `/filestorage/api/v1/document/upload_homework`
		let error = null
		const res = await this.$axios
			.post(url, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			.catch((err) => {
				// eslint-disable-next-line
				console.error(err)
				error = err
			})

		if (res) ctx.commit('setUploadedFiles', res.data.id)
		if (error) {
			return error
		} else {
			return res.data.id
		}
	},

	async addHomeWork(ctx, data) {
		const url = `/homeworks/api/v1/psychologist/homework`
		const res = await this.$axios
			.$post(url, data)
			.catch((err) => {
				console.error(err)
			})
		if (res) return res
	},

	async updateHomeWork(ctx, data) {
		const url = `/homeworks/api/v1/psychologist/homework/${data.id}`
		const res = await this.$axios
			.$patch(url, data)
			.catch((err) => {
				console.error(err)
			})
		if (res) return res
	},

	async addClientHomeWork(ctx, data) {
		const url = `/homeworks/api/v1/client/homework`
		const res = await this.$axios
			.$post(url, data)
			.catch((err) => {
				console.error(err)
			})
		if (res) return res
	},

	clear(ctx) {
		ctx.commit('clearUploadedFiles')
	},

	removeUploadedFile(ctx, idx) {
		ctx.commit('removeUploadedFile', idx)
	},
}
