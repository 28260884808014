const apiModulePath = '/globalsettings/api/v1/public/settings/'

const initialState = {
	session: [],
	calendar: [],
	common: {
		//TIME_IN_HOURS_BEFORE_SESSION_RESCHEDULING: 0,
		SESSION_DURATION_MINUTES: null,
		TIME_IN_HOURS_BEFORE_SESSION_RESCHEDULING: null,
		MIN_REST_INTERVAL_MINUTES: null,
	},
}

export const state = () => ({
	...initialState,
})

export const mutations = {
	setSessionConfig(state, config) {
		state.session = config
	},
	setCalendarConfig(state, config) {
		state.calendar = config
	},
	setConfigKey(state, {key, value}) {
		state.common[key] = value
	},
}

export const getters = {
	getCommonSettings(state) {
		return state.common
	},
	getFreeCancellationTime(state) {
		return state.common['TIME_IN_HOURS_BEFORE_SESSION_CANCELLATION']
	},
	getReschedulingHours(state) {
		return state.common['TIME_IN_HOURS_BEFORE_SESSION_RESCHEDULING']
	},
	getSessionDurationMinutes(state) {
		return state.common['SESSION_DURATION_MINUTES']
	},
}

export const actions = {
	async fetchGlobalSettings({commit}) {
		// eslint-disable-next-line no-console
		const response = await this.$axios.$get(apiModulePath).catch((err) => {
			// eslint-disable-next-line
			console.error(err)
		})

		if (response) {
			response.forEach((item) => {
				commit('setConfigKey', {key: item.key, value: item.value})
			})
		}
		return Promise.resolve('GS updated')
	},
	getSettingByKey({state}, key) {
		const s = state.common[key]
		if (!s) {
			// eslint-disable-next-line no-console
			console.error(`globalSettings ${key} not found`)
			return null
		}
		return s
	},
}
