import { render, staticRenderFns } from "./UiMenuLeft.vue?vue&type=template&id=1b6e5e8c&scoped=true&"
import script from "./UiMenuLeft.vue?vue&type=script&lang=js&"
export * from "./UiMenuLeft.vue?vue&type=script&lang=js&"
import style0 from "./UiMenuLeft.vue?vue&type=style&index=0&id=1b6e5e8c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b6e5e8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiMenuUser: require('/usr/src/app/components/UI/MenuLeftNew/UiMenuUser.vue').default,UiMenuItem: require('/usr/src/app/components/UI/MenuLeftNew/UiMenuItem.vue').default})
