/* eslint-disable camelcase */
import {extend, setInteractionMode} from 'vee-validate';
import {email, integer, max, max_value, min_value, required} from 'vee-validate/dist/rules';

setInteractionMode('passive');

extend('email', {
	...email,
	message: 'Проверьте правильность электронной почты',
});

extend('required', {
	...required,
	message: 'Значение этого поля обязательно'
});

extend('integer', {
	...integer,
	message: 'Значение должно быть целым числом'
});

extend('min_value', {
	...min_value,
	message: 'Значение должно быть {min} и больше'
});

extend('max_value', {
	...max_value,
	message: 'Значение должно быть {max} и меньше'
});

extend('max', {
	...max,
	message: 'Превышено количество символов, максимальное {length}'
});

extend('phone', {
	params: ['target'],
	validate(value, {target}) {
		return target;
	},
	message: 'Формат телефона неверен'
});

const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

extend('password', {
	validate(value) {
		return PASSWORD_REGEX.test(value);
	},
	message: 'Пароль не соответствует требованиям: длина не менее 8 символов, должен содержать символы большого и малого регистров, цифры и спецсимволы "@$!%#?&{*}.'
});

extend('equal_password', {
	params: ['target'],
	validate(value, {target}) {
		return value === target;
	},
	message: 'Пароли не совпадают'
});

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

extend('url', {
	validate(value) {
		return URL_REGEX.test(value);
	},
	message: 'Введите корректную ссылку'
});

const CYRILLIC_REGEX = /^[^a-zA-Z-0-9\s][А-яËё\u0401\s\-]*$/u

extend('cyrillic', {
	validate(value) {
		return CYRILLIC_REGEX.test(value);
	},
	message: 'Поддерживаются только кириллические символы'
});

extend('file', {
	validate(value) {
		return Array.isArray(value) ? !value.find(file => file.error) : !value.error;
	},
	message: 'Ошибка загрузки файла'
});

extend('file_upload', {
	validate(value) {
		return Array.isArray(value) ? !value.find(file => file.isUploading) : !value.isUploading;
	},
	message: ' '
});

extend('max_date', {
	params: ['date'],
	validate(value, {date}) {
		return new Date(date) > new Date(value) || date === value;
	},
	message: 'Дата не может быть больше {date}'
});

extend('min_date', {
	params: ['date'],
	validate(value, {date}) {
		return new Date(date) < new Date(value) || date === value;
	},
	message: 'Дата не может быть меньше {date}'
});
