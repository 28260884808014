
	import gtmButtonClick from '@/mixins/gtm-button-click'

	export default {
		components: {},
		mixins: [gtmButtonClick],
		props: {
			isBtnVisible: Boolean,
			btnText: {type: String, default: 'Обратная связь'},
		},
		methods: {
			openFeedbackPopup() {
				this.gtmButtonClick(
					this.btnText,
					'left-menu',
					'button',
					'click',
					this.gtmUserPage
				)
				this.$emit('openFeedbackPopup')
			},
		},
	}
