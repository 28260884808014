
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import ClickOutside from 'vue-click-outside'
import UiIcon from '@/components/UI/Icon/UiIcon.vue'

export default {
  directives: {
    ClickOutside,
  },
  components: {
    UiIcon,
  },
  props: {
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    cls: { type: String, default: '' },
    clsMb: { type: String, default: '' },
    maxWidth: { type: Number, default: 600 },
    isVisible: { type: Boolean, default: false },
    hideCloseIcon: { type: Boolean, default: false },
  },
  data() {
    return {
      scrollPosition: null,
    }
  },
  computed: {
    isSafari() {
      let result = false
      if (
        process.client &&
        navigator.userAgent.search('Safari') >= 0 &&
        navigator.userAgent.search('Chrome') < 0
      ) {
        result = true
      }

      return result
    },
  },
  mounted() {
    // let vh = window.innerHeight * 0.01;
    // // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty("--vh", `${vh}px`);
    this.setResizeHandler()
    if (this.isVisible) {
      disablePageScroll(this.$refs.popup)
    }

    if (this.isVisible && this.isSafari) {
      this.scrollPosition = this.getScrollTop()
      this.$root.$el.style.touchAction = 'none'
      this.$root.$el.style.overflow = 'hidden'
      this.$root.$el.style.maxHeight = '100vh'
    }
  },
  destroyed() {
    this.removeResizeHandler()
    enablePageScroll()

    if (this.isVisible && this.isSafari) {
      this.$root.$el.style.touchAction = null
      this.$root.$el.style.overflow = null
      this.$root.$el.style.maxHeight = null
      this.setPositionScroll()
    }
  },
  methods: {
    setResizeHandler() {
      process.client && window.addEventListener('resize', this.setHeight)
    },
    removeResizeHandler() {
      process.client && window.removeEventListener('resize', this.setHeight)
    },
    onClose() {
      this.$emit('closePopup', false)
    },
    setHeight() {
      const vh = process.client ? window.innerHeight * 0.01 : 0
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    getScrollTop() {
      return process.client
        ? document.documentElement.scrollTop || document.body.scrollTop
        : 0
    },
    setPositionScroll() {
      if (process.client) {
        window.scrollTo(0, this.scrollPosition)
      }
    },
  },
}
