const apiModulePath = 'homeworks/api/v1'

export const state = () => ({
	homeWorksList: [],
})

export const mutations = {
	setHomeWorks(s, data) {
		s.homeWorksList = data
	},
}

export const actions = {
	async fetchHomeWorks(ctx, ids) {
		let str = ''
		ids.forEach((id) => {
			str += `&session_ids=${id}`
		})
		const url = `${apiModulePath}/client/homeworks?page=0&page_size=50${str}`
		const res = await this.$axios
			.get(url)

			.catch((err) => {
				console.error(err)
			})

		if (res) ctx.commit('setHomeWorks', res.data.results)
	},

	async deleteClientHomeWork(ctx, {id, sessionIds}) {
		const url = `${apiModulePath}/client/file/${id}`
		await this.$axios.delete(url).catch((err) => {
			console.error(err)
		})

		await ctx.dispatch('fetchHomeWorks', sessionIds)
		await ctx.commit('uploadFile/clearUploadedFiles', null, {root: true})
	},

	async getPsyHomeworkToSession(ctx, id) {
		const url = `${apiModulePath}/psychologist/homework?session_id=${id}`
		const res = await this.$axios
			.get(url)

			.catch((err) => {
				console.error(err)
			})

		if (res) return res.data
	},
}
