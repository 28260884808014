
	import vSelect from "vue-select";
	import {ValidationProvider} from 'vee-validate';
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";
	import IconButton from "@/components/ui-kit/buttons/IconButton.vue";

	export default {
		name: "BaseSelect",
		components: {IconButton, UiIcon, vSelect, ValidationProvider},
		props: {
			id: {type: String, required: true},
			name: {type: String, required: true},
			value: {type: [String, Number], default: ''},
			label: {type: String, default: ''},
			placeholder: {type: String, default: ''},
			required: {type: Boolean},
			clearable: {type: Boolean},
			searchable: {type: Boolean},
			isLink: {type: Boolean},
			mode: {
				validator(value) {
					// https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
					return ['aggressive', 'passive', 'eager', 'lazy'].includes(value)
				},
				default: 'passive',
			},
			rules: {type: Array, default: () => []}, // ['required']
			options: {type: Array, default: () => []}, // [{ label: String, value: [String, Number] }]
			selectedAsIcon: Boolean,
			hideTick: Boolean
		},
		computed: {
			validateRules() {
				const rules = [...this.rules];

				if (this.required && !rules.includes('required')) {
					rules.push('required');
				}

				return rules.join("|");
			},
			selectedValue() {
				return this.options.find(option => option.value === this.value);
			}
		},

		methods: {
			changeValue(option) {
				this.$emit('input', option?.value || '');
				this.$emit('changed', option);

				if (this.mode === 'passive') {
					this.$refs.provider.reset();
				}
			},
		}
	}
