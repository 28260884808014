
	import {mapActions, mapState} from "vuex";

	import UiIcon from "@/components/UI/Icon/UiIcon";
	import UiButton from '@/components/ui-kit/buttons/Button.vue'
	import ButtonLink from "@/components/ui-kit/buttons/ButtonLink";
	import UiSelect from "@/components/ui-kit/form/Select.vue";
	import IconButton from "@/components/ui-kit/buttons/IconButton";
	import {tzData} from "@/helpers/tzData";
	import {zonedTimeToUtc, utcToZonedTime} from "date-fns-tz";

	export default {
		name: "PopupDefaultTimezone",
		components: {IconButton, ButtonLink, UiButton, UiIcon, UiSelect},
		props: {
			session: Object,
		},
		data() {
			return {
				timezoneOptions: tzData.map(tz => ({label: tz.name, value: tz.value})),
				currentTz: '',
			}
		},

		computed: {
			...mapState('user', ['timeZone']),

			currentTime () {
				return utcToZonedTime(zonedTimeToUtc(new Date()), this.currentTz);
			}
		},

		methods: {
			tzChange() {
				// this.gtmButtonClick(
				// 	'Часовой пояс',
				// 	'Настройка часового пояса',
				// 	'button',
				// 	'click',
				// 	'clientPage'
				// )
			},

			async save() {
				try {
					this.$store.commit('user/setTimezone', this.currentTz);
					this.$cookies.set('default_timezone', this.currentTz);
					this.$emit('success');
					this.$modal.hide('default-timezone-modal');
				} catch (e) {
					console.log(e);

					if (this.$sentry) {
						this.$sentry.captureException(e)
					}
				}
			}
		},
		async mounted() {
			this.currentTz  = this.timeZone;
		}
	}
