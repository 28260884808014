const apiModulePath = `/sso/api/v1/profile`

const initialState = {
  testSettings: 'testSettings',
}

export const state = () => ({
  ...initialState,
})

const API_SETTINGS = `${apiModulePath}/settings`;

export const actions = {
  async updateSettingsCli(_, payload) {
    const url = `${API_SETTINGS}/client`
    const data = await this.$axios
      .$patch(url, payload)
      .catch((error) => {
        return error.response
      })

    return data || false
  },
  async updateSettingsPsy(_, payload) {
    const url = `${API_SETTINGS}/psychologist`
    const data = await this.$axios
      .$patch(url, payload)
      .catch((error) => {
        return error.response
      })

    return data || false
  },
  async updateTzPsy({ rootState }, tz) {
    const user = rootState.user;
    // console.log("->", user)

    const url = `${API_SETTINGS}/psychologist`

    const data = await this.$axios
      .$patch(url, {
        birthdate: user.birthDate,
        email: user.email,
        username: user.username,
        time_zone: tz,
      })
      .catch((error) => {
        return error.response
      })

    return data || false
  },
  async updateSettingsPhone(_, number) {
    const url = `${apiModulePath}/phone-number`

    const data = await this.$axios
      .$patch(url, { phone_number: number })
      .catch((error) => {
        return error.response
      })

    return data || false
  },
  async verifyPhone(_, payload) {
    const url = `${apiModulePath}/verify-phone`

    const data = await this.$axios
      .$post(url, payload)
      .catch((error) => {
        return error.response
      })

    return data || false
  },
  async deleteProfile(_) {
    const url = `${API_SETTINGS}/client`

    const data = await this.$axios
      .$delete(url)
      .catch((error) => {
        return error.response
      })

    return data || false
  },
  async changePassPsy(_, payload) {
    const url = `${apiModulePath}/password`

    const data = await this.$axios
      .$patch(url, payload)
      .catch((error) => {
        return error.response
      })

    return data || false
  }
}
