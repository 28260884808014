
	import ClickOutside from 'vue-click-outside'
	import UiIcon from '@/components/UI/Icon/UiIcon'

	export default {
		components: {UiIcon},
		directives: {
			ClickOutside,
		},
		model: {prop: 'value', event: 'change'},
		props: {
			options: {type: Array, default: () => []},
			placeholder: {type: String, default: ''},
			disabled: {type: Boolean, default: false},
			label: {type: String, default: ''},
			error: {type: String, default: ''},
			required: {type: Boolean, default: false},
			description: {type: String, default: ''},
			value: {type: [Object, String, Number], default: () => {}},
			returnObject: {type: Boolean, default: false},
			isClear: {type: Boolean, default: true},
			withSeparator: Boolean
		},
		watch: {
			value () {
				if(this.value) {
					this.opts = JSON.parse(JSON.stringify(this.options))
					this.current =
						this.opts.find(
							(item) =>
								item.selected ||
								item.id === this.value?.id ||
								item.value === this.value
						) || {}
				}
			},
		},
		data() {
			return {
				current: {},
				open: false,
				opts: [],
			}
		},
		mounted() {
			this.opts = JSON.parse(JSON.stringify(this.options))
			this.current =
				this.opts.find(
					(item) =>
						item.selected ||
						item.id === this.value?.id ||
						item.value === this.value
				) || {}
		},
		destroyed() {
		},
		methods: {
			toggleOpen() {
				this.open = !this.open
				if (this.open) this.$emit('focus')
			},
			chooseOption(option) {
				this.current.selected = false
				this.current = option
				this.current.selected = true

				this.$emit('change', this.returnObject ? option : option.value)
				this.closeSelect()
			},
			closeSelect() {
				this.open = false
			},
			clear() {
				this.$emit('clear', this.current.value)
				this.$emit('change', undefined)
				this.open = false
				this.current.selected = false
				this.current = {}
			},
		},
	}
