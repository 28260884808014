import { render, staticRenderFns } from "./PopupNotify.vue?vue&type=template&id=31655e39&scoped=true&"
import script from "./PopupNotify.vue?vue&type=script&lang=js&"
export * from "./PopupNotify.vue?vue&type=script&lang=js&"
import style0 from "./PopupNotify.vue?vue&type=style&index=0&id=31655e39&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31655e39",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/usr/src/app/components/UI/Icon/UiIcon.vue').default})
