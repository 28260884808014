const initialState = {
  isAccepted: false,
}

export const state = () => ({...initialState})

export const mutations = {
  setCookiesPolicy(state, flag) {
    state.isAccepted = flag;
  },
}

export const actions = {
  readCookiesPolicyFromStorage({ commit }) {
    const cookiesPolicyFromStorage = localStorage.getItem('cookies-policy');
    if (cookiesPolicyFromStorage) {
      commit('setCookiesPolicy', JSON.parse(cookiesPolicyFromStorage));
    }
  },
  saveCookiesPolicyToStorage({ commit }, flag) {
    localStorage.setItem('cookies-policy', flag);
    commit('setCookiesPolicy', flag);
  },
}
