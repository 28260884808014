/* eslint-disable camelcase */
import { mapState, mapActions } from 'vuex'
import { userTypes } from '@/store/user'

export default {
  computed: {
    ...mapState('user', ['id', 'userType', 'timeZone', 'createdAt']),

    viewPage() {
      return this.$route.name
    },
  },

  watch: {
    /*
     * Отслеживаем смену страницы и отправляем соответствующее событие в гугл аналитику
     */
    viewPage: {
      immediate: true,
      handler() {
        this.sendViewPageEvt()
      },
    },

    /*
     * Тащим created_at отдельно для клиента, пока у всех юзеров не появится
     */
    userType(type) {
      if (type === userTypes.client && !this.createdAt) {
        this.fetchClient()
      }
    },
  },

  methods: {
    ...mapActions('user', ['fetchClient']),

    sendViewPageEvt() {
      const { id = '0', userType = '0', timeZone = '+3', createdAt = '' } = this
      const data = {
        event: 'GAPageview',
        userId: id,
        dateRegistration: createdAt,
        userType,
        timezone: timeZone,
      }

      this.$gtm.push({
        ...data,
        eventCallback() {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log(
              `ALL tags sendViewPageEvt have now fired. User data: ${JSON.stringify(
                data
              )}`
            )
          }
        },
      })
    },
  },
}
