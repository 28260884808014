
	import {mapState, mapActions} from 'vuex'
	import gtmViewPage from '~/mixins/gtm-view-page'

	import UiFooter from '@/components/UI/Footer/UiFooter'
	import PopupError from '@/components/Client/Popup/PopupError'

	export default {
		name: 'no-sidebar',
		components: {
			UiFooter,
			PopupError,
		},

		mixins: [gtmViewPage],

		data: () => ({
			isVisibleErrorPopup: false,
			actionError: null,
			tokenInterval: null,
		}),
		computed: {
			...mapState({
				user: (s) => s.user,
			}),
			...mapState('auth', ['isAuth', 'authTokens', "parsedToken"]),
		},
		watch: {
			authTokens: {
				handler() {
					this.setTokenRefreshInterval()
				},
				deep: true
			}
		},
		beforeDestroy() {
			clearInterval(this.tokenInterval);
			this.tokenInterval = null;
		},
		async mounted() {
			await this.$store.dispatch("globalSettings/fetchGlobalSettings")
		},
		methods: {
			...mapActions({
				storeSetCookiesAuth: 'auth/setCookiesAuth',
				storeGetClientAnswer: 'question/getClientAnswer',
				storeRefreshToken: 'auth/refreshTokenAction',
			}),
			setTokenRefreshInterval() {
				if (this.authTokens.token) {
					const diff = this.parsedToken.exp - this.parsedToken.iat;
					if (!this.tokenInterval && diff) {

						this.tokenInterval = setInterval(() => {
							this.storeRefreshToken()
						}, (diff / 2) * 1000);
					}
				} else if (this.tokenInterval) {
					clearInterval(this.tokenInterval);
					this.tokenInterval = null;
				}
			},
		},
	}
