import {userTypes} from "@/store/user";
import {mapActions} from "vuex";

export default {
	async beforeMount() {
		if (this.$store.state.user.userType === userTypes.client) {
			await this.$store.dispatch("user/fetchClient");
			await this.getClientPromocodes();
		}

		const route = this.$route.path
		this.guard(route)
	},
	methods: {
		...mapActions({
			storeSetCookiesAuth: 'auth/setCookiesAuth',
			storeGetClientAnswer: 'question/getClientAnswer',
			getClientPromocodes: "user/getClientPromocodes",
		}),

		guard(routeObj) {
			const redirect_url = this.$cookies.get('redirect_url');

			if (redirect_url) {
				return
			}

			const route = typeof routeObj === 'string' ? routeObj : routeObj.path
			process.client &&
			this.storeSetCookiesAuth()
				.then(() => {
					const isClient = this.$store.state.user.userType === userTypes.client
					const isPsychologist = this.$store.state.user.userType === userTypes.psychologist

					if (isClient && route.includes('/client/sessions/session')) {
						this.$router.push('/search/psychologist')
					} else if (this.$const.whitelistedPaths.includes(route)) {
						if (isClient) {
							this.$router.replace(this.$const.mainClientPrivatePath)
						} else if (isPsychologist)
							this.$router.replace(this.$const.mainPsychologistPrivatePath)
					}
				})
				.catch((err) => {
					const isClient = this.$const.clientPrivatePaths.includes(route)
					const isPsychologist = this.$const.psychologistPrivatePaths.includes(route)
					if (!this.$const.whitelistedPaths.includes(route)) {
						if (isClient) {
							this.$router.replace(this.$const.clientLoginPath)
						} else if (isPsychologist) {
							this.$router.replace(this.$const.psychologistLoginPath)
						}
					}
					return err
				})
		},
	},
}
