import {FROM_SESSION_CLI, FROM_SESSION_PSY} from '@/api/common'
import {userTypes} from '@/store/user'
import {SESSION_STATUSES} from '@/constants/sessions'
import {serializeSearchParams} from '@/helpers/network'

const apiModulePath = '/sessions/api/v1'

export const state = () => ({
	sessions: [],
	lastSessionsCount: 0,
	expectedSessionsCount: 0,
	currentSessionId: '',
	timerStatus: {
		current_datetime: '',
		slot_datetime: '',
		session_status: '',
		time_to_start: 0, // sec
		time_from_start: 0, // sec
	},
	timerNote: '',
	topics: []
})

export const getters = {
	/**
	 *getCurrentSessionData
	 *
	 * @returns
	 * @param state
	 */
	getCurrentSessionData(state) {
		const currentSessionId = state.currentSessionId
		if (!currentSessionId) {
			return {}
		}

		let currentSession = state.sessions.filter((s) => s.id === currentSessionId)
		if (Array.isArray(currentSession)) {
			currentSession = currentSession[0]
		}
		return currentSession
	},
	getCurrentSessionId(state) {
		return state.currentSessionId
	},
	isTimerStatusFinished(state) {
		return state.timerStatus?.session_status === SESSION_STATUSES.FINISHED
	},
}

export const mutations = {
	setSessions(state, {sessions}) {
		state.sessions = [...sessions]
	},

	setTotalLastSessions(state, lastSessionsCount) {
		state.lastSessionsCount = lastSessionsCount
	},

	setTotalExpectedSessions(state, expectedSessionsCount) {
		state.expectedSessionsCount = expectedSessionsCount
	},

	setCurrentSessionId(state, id) {
		state.currentSessionId = id
	},

	updateStatusReady(state, response) {
		state.sessions[0] = state.sessions[0].is_ready_connect =
			response.is_ready_connect
	},

	setTimerStatus(state, timerStatus) {
		state.timerStatus = {...state.timerStatus, ...timerStatus}
	},

	setTimerNote(state, timerNote) {
		state.timerNote = timerNote || ''
	},

	setTopics(state, topics) {
		state.topics = topics || []
	},
}

export const actions = {
	async fetchTimerStatus({commit, rootState}, sessionId) {
		try {
			const url = `${
				rootState.user?.userType === userTypes.client
					? FROM_SESSION_CLI
					: FROM_SESSION_PSY
			}/${sessionId}/timer_status/`
			const res = await this.$axios.get(url)
			commit('setTimerStatus', res?.data || {})
			return res
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			throw new Error(err)
		}
	},

	/**
	 *getClientSessions
	 *
	 * @param {*} { commit, rootState }
	 * @param { Object } params Параметры запроса
	 * @param { number } params.page
	 * @param { number } params.page_size
	 * @param { string } params.psychologist_id
	 * @param { string } params.session_status
	 * @returns
	 */
	async getClientSessions({commit}, query = {}) {
		try {
			const params = serializeSearchParams({
				page_size: 100,
				ordering_by: 'SLOT_DATETIME',
				...query,
				page: query?.page && query?.page > 0 ? query?.page - 1 : 0,
			})

			const url = `${apiModulePath}/client/session`
			const data = await this.$axios.$get(url, {params})

			commit('setSessions', {sessions: data.results || []})
			if (query?.session_status?.includes('FINISHED')) {
				commit('setTotalLastSessions', data.count)
			}
			if (query?.session_status?.includes('EXPECTED')) {
				commit('setTotalExpectedSessions', data.count)
			}
			return data
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			throw new Error(err)
		}
	},

	/**
	 * finishSession
	 *
	 * @param {*} { commit, rootState }
	 * @param {*} sessionId
	 * @returns
	 */
	async finishSession({commit, rootState}, payload) {
		const url = `${apiModulePath}/psychologist/session/${payload.id}/finish`
		const data = await this.$axios
			.$patch(url, payload.params)
			.catch((error) => {
				console.log(error, rootState)
				return Promise.reject(error.response.data)
			})
		return data || false
	},

	async getSessionByIdPsy({commit, rootState}, sessionId) {
		const url = `${apiModulePath}/psychologist/session?page=0&page_size=1&session_id=${sessionId}`
		const data = await this.$axios.$get(url).catch((error) => {
			console.log(error, rootState)
			return Promise.reject(error.response.data)
		})
		if (data && data.results) {
			commit('setSessions', {
				sessions: data.results,
			})
			return data
		} else {
			return false
		}
	},

	async getSessionByIdClient({commit, rootState}, sessionId) {
		const url = `${apiModulePath}/client/session?page=0&page_size=1&session_id=${sessionId}`
		const data = await this.$axios.$get(url).catch((error) => {
			console.log(error, rootState)
			return Promise.reject(error.response.data)
		})
		if (data && data.results) {
			commit('setSessions', {
				sessions: data.results,
			})
			return data
		} else {
			return false
		}
	},

	//todo: implement in store
	async getSessionById({commit, rootState}, sessionId) {
		const url = `${apiModulePath}/client/session/${sessionId}`
		const data = await this.$axios.$get(url).catch((error) => {
			return Promise.reject(error.response.data)
		})

		commit('setSessions', {
			sessions: [data],
		})

		return data || null;
	},

	async rescheduleSessionPsy({commit}, {calendarSlotId, sessionId}) {
		const url = `${apiModulePath}/psychologist/session/rescheduling`
		const data = await this.$axios
			.$post(url, {
				calendar_slot_id: calendarSlotId,
				session_id: sessionId,
			})
			.catch((error) => {
				return error.response.data
			})
		return data
	},

	async cancellationSessionPsy(_, {sessionId, reasonType, reasonText}) {
		const url = `${apiModulePath}/psychologist/session/cancellation/${sessionId}`
		const res = await this.$axios.$post(url, {
			reason_cancellation_type: reasonType,
			reason_cancellation_text: reasonText,
		})
		return res
	},

	async cancellationSessionCli(_, sessionId) {
		const url = `${apiModulePath}/client/session/cancellation/${sessionId}`
		const res = await this.$axios.$patch(url)
		return res
	},

	async rescheduleSessionCli({commit}, {calendarSlotId, sessionId}) {
		const url = `${apiModulePath}/client/session/rescheduling`
		await this.$axios.$post(url, {
			calendar_slot_id: calendarSlotId,
			session_id: sessionId,
		})
	},

	async confirmRescheduleSessionCli(_, {sessionId}) {
		const url = `${apiModulePath}/client/session/confirm-rescheduling?session_id=${sessionId}`
		const data = await this.$axios.$post(url).catch((error) => {
			return error.response.data
		})
		if (data) {
			return data
		} else {
			return false
		}
	},

	async getPsySessionById({commit}, id) {
		const url = `${apiModulePath}/psychologist/session/${id}`
		const sessionInfo = await this.$axios.get(url);
		if (sessionInfo && sessionInfo.data) {
			commit('setSessions', {
				sessions: [sessionInfo.data],
			})
			return sessionInfo
		} else {
			return false
		}
	},

	async getFinishedSessions(_) {
		const url = `${apiModulePath}/psychologist/session?page=0&page_size=1000&session_status=FINISHED`
		return await this.$axios.get(url)
	},

	async bookSlot(_, payload) {
		const url = `${apiModulePath}/client/session`
		const {data} = await this.$axios.post(url, payload)
		return data
	},

	async bookSlotByPsychologist(_, payload) {
		const url = `${apiModulePath}/psychologist/session`
		const {data} = await this.$axios.post(url, payload)
		return data
	},

	async createB2BPayment({commit}, id) {
		const url = `${apiModulePath}/client/session/${id}/b2b-approve`
		return await this.$axios.$post(url)
	},

	async setPsyReadyStatus({state, commit}) {
		const currentSessionId = state.currentSessionId
		const url = `${apiModulePath}/psychologist/session/${currentSessionId}/ready`

		await this.$axios
			.$patch(url)
			.then((res) => {
				commit('updateStatusReady', res)
			})
			.catch((error) => {
				console.log(error)
				return Promise.reject(error.response.data)
			})
	},

	setSessionIdFromCookies({commit}) {
		const sessionId = this.$cookies.get('sessionId')
		if (sessionId) {
			commit('setCurrentSessionId', sessionId)
		} else {
			throw new Error('no session cookies')
		}
	},

	setCookiesSessionId(ctx, sessionId) {
		this.$cookies.set('sessionId', sessionId, {
			path: '/',
		})
	},

	setCookiesCalendarId(ctx, calendarId) {
		this.$cookies.set('calendarId', calendarId, {
			path: '/',
		})
	},

	//Topics
	// GET.     /sessions/api/v1/psychologist/session/{session_id} - возращается массив тем у сессии (поле topics[])
// GET      /sessions/api/v1/psychologist/topics - получить список доступных тем (отсортирован в алфавитном порядке)
// PATCH /sessions/api/v1/psychologist/session/{session_id}/topics - пересоздать темы для сессии (передача пустого массива равносильна удалению всех тем у сессии)

	async getTopics({commit}) {
		const {data} =  await this.$axios.get(`${apiModulePath}/psychologist/topics`);
		commit('setTopics', data)
	},

	async updateSessionTopics({commit}, {session_id, topics}) {
		const {data} =  await this.$axios.patch(`${apiModulePath}/psychologist/session/${session_id}/topics`, topics);
	},

	async sendSessionLogs({commit}, payload) {
		const {data} =  await this.$axios.post(`${apiModulePath}/${payload.userType}/session/${payload.session_id}/events/connection`, payload.data);
	},



}
