const apiModulePath = `/filestorage/api/v1/document`

const initialState = {
	test: '',
	filesFromStorage: {},
}

export const state = () => ({
	...initialState,
})

export const mutations = {
	/**
	 *setLoadedFile
	 *
	 * @param {*} state
	 * @param {*} file
	 */
	setLoadedFile(state, file) {
		state.filesFromStorage = {...state.filesFromStorage, [file.id]: file}
	},
}

export const actions = {
	/**
	 * uploadImage
	 * @param {*} params
	 */
	async uploadImage({commit}, data) {
		const url = `${apiModulePath}/upload_image`

		try {
			const response = await this.$axios.$post(url, data)

			return response
		} catch (error) {
			if (this.$sentry) {
				this.$sentry.captureException(error)
			}

			return error
		}
	},

	/**
	 * upload diplom
	 * @param {*} params
	 */
	async uploadDiploma({commit}, data) {
		const url = `${apiModulePath}/upload_diploma`
		const response = await this.$axios.$post(url, data)

		return response
	},

	/**
	 * upload diplom
	 * @param {*} params
	 */
	async uploadArticle({commit}, data) {
		const url = `${apiModulePath}/upload_article`
		const response = await this.$axios.$post(url, data)

		return response
	},

	/**
	 * downloadFile
	 *
	 * @param {*} { commit }
	 * @param {*} file
	 */
	async downloadFile({commit}, file) {
		if (!file) {
			return false
		}
		try {
			const url = `${apiModulePath}/file/${file.id}`
			const response = await this.$axios.$get(url, {
				responseType: 'arraybuffer',
			})

			const base64File = Buffer.from(response, 'binary').toString('base64')

			if (base64File) {
				const fullFileBase64 =
					`data:application/octet-stream;base64,` + base64File
				commit('setLoadedFile', {...file, base64: fullFileBase64})
				if (process.client) {
					function saveContent(fileName) {
						const link = document.createElement('a')
						link.download = fileName
						link.href = fullFileBase64
						link.click()
					}

					saveContent(file.name)
				}
			}
			return true
		} catch (err) {
			if (this.$sentry) {
				this.$sentry.captureException(err)
			}

			return false
		}
	},
}
