
	export default {
		name: "IconButton",
		props: {
			// shape: {
			// 	validator(value) {
			// 		return ['circle', 'square'].includes(value)
			// 	},
			// 	default: 'circle'
			// },
			// size: {
			// 	validator(value) {
			// 		return ['24', '36', '44', '52'].includes(value)
			// 	},
			// 	default: '36'
			// },
		},
		computed: {
			classes() {
				const classes = [];
				// classes.push(`btn--${this.shape}`);
				// classes.push(`btn--${this.size}`);
				return classes;
			}
		}
	}
