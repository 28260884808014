import {Hardware} from "voximplant-websdk";
import testSound from '@/assets/test-sound.wav';

export default function ({store, $sentry}, inject) {
	let changingDevices = false

	const audioContext = new (window.AudioContext || window.webkitAudioContext)()
	const analyser = audioContext.createAnalyser()

	let inputStream = null
	let currentSource = null
	analyser.fftSize = 1024;
	const analyserData = new Uint8Array(analyser.frequencyBinCount);

	const audioElement = document.createElement("audio");
	audioElement.src = testSound;
	audioElement.volume = 0.5;
	document.body.append(audioElement);

	async function getDeviceAccess(video = false, audio = false) {
		// if (audio) store.commit('voximplant/setMicAccessGranted', false);
		if(audio !== store.state.voximplant.micAccessGranted) {
			try {
				await navigator.mediaDevices
					.getUserMedia({audio, video})
					.then((stream) => {
						stream.getTracks().forEach(function (track) {
							track.stop()
						})
						if (audio) store.commit('voximplant/setMicAccessGranted', true)
					})
					.catch(() => {
						if (audio) store.commit('voximplant/setMicAccessGranted', false)
					})
			} catch (e) {
				console.log(e);
			}
		}
	}

	function getDefaultDevice(arrayDevices) {
		const defaultDevice =
			arrayDevices.find((x) => x.id === 'default') || arrayDevices[0]
		if (!defaultDevice) {
			// eslint-disable-next-line no-console
			console.log('>>> No default device')
			return []
		}
		const defaultGroup = defaultDevice.group

		const secondDefault = arrayDevices.find(
			(x) => x.id !== 'default' && x.group === defaultGroup
		)
		return [defaultDevice, secondDefault]
	}

	async function audioInputChangeHandler() {
		const manager = Hardware.AudioDeviceManager.get();

		try {
			const audioInputDevices = await manager.getInputDevices()
			store.commit('voximplant/setAudioInputDevices', audioInputDevices)

			let currentDevice = null;
			if (store.state.voximplant.currentDevices.input) {
				currentDevice = audioInputDevices.find((x) => x.id === store.state.voximplant.currentDevices.input.id);
			}

			if (!currentDevice) {
				const [defaultDevice, secondDefaultDevice] = getDefaultDevice(audioInputDevices)
				const newDeviceInput = secondDefaultDevice || defaultDevice

				store.commit('voximplant/setCurrentDevice', {
					type: 'input',
					device: newDeviceInput,
					showNotification: true
				})

				if (newDeviceInput) {
					await updateInputStream(newDeviceInput.id)
				}

				return newDeviceInput;
			}
		} catch (error) {
			// eslint-disable-next-line
			console.error('>>> audioInputChangeHandler error', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function audioOutputChangeHandler() {
		const manager = Hardware.AudioDeviceManager.get();

		try {
			const audioOutputDevices = await manager.getOutputDevices()
			store.commit('voximplant/setAudioOutputDevices', audioOutputDevices)
			let currentDevice = null;
			if (store.state.voximplant.currentDevices.output) {
				currentDevice = audioOutputDevices.find((x) => x.id === store.state.voximplant.currentDevices.output.id);
			}

			if (!currentDevice) {
				const [defaultDevice, secondDefaultDevice] = getDefaultDevice(audioOutputDevices)
				const newDeviceOutput = secondDefaultDevice || defaultDevice

				store.commit('voximplant/setCurrentDevice', {
					type: 'output',
					device: newDeviceOutput,
					showNotification: true
				})

				if (newDeviceOutput) {
					updateAudioElement(newDeviceOutput.id)
				}

				return newDeviceOutput;
			}
		} catch (error) {
			// eslint-disable-next-line
			console.error('>>>audioOutputChangeHandler error', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function cameraChangeHandler() {
		try {
			const cameraManager = Hardware.CameraManager.get()
			const cameraDevices = await cameraManager.getInputDevices()
			store.commit('voximplant/setCameraDevices', cameraDevices)

			let currentDevice = null;
			if (store.state.voximplant.currentDevices.camera) {
				currentDevice = cameraDevices.find((x) => x.id === store.state.voximplant.currentDevices.camera.id);
			}

			console.log(`Camera OLD ${store.state.voximplant.currentDevices.camera?.name}: ${store.state.voximplant.currentDevices.camera?.id}`);
			console.log(`Camera current ${currentDevice?.name}: ${currentDevice?.id} `);

			const [defaultDevice, secondDefaultDevice] = getDefaultDevice(cameraDevices)
			const newCamera = secondDefaultDevice || defaultDevice
			if (!currentDevice) {
				store.commit('voximplant/setCurrentDevice', {
					type: 'camera',
					device: newCamera,
					showNotification: true
				})
			}

			store.commit('voximplant/setCameraError', '')
			return newCamera
		} catch (error) {
			store.commit('voximplant/setCameraError', error.toString())
			// eslint-disable-next-line
			console.error('>>>cameraChangeHandler error', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function deviceChangedHandler() {
		if (changingDevices) {
			console.warn('>>> deviceChangedHandler SKIP')
			return
		}
		changingDevices = true;

		await audioInputChangeHandler();
		await audioOutputChangeHandler();
		await cameraChangeHandler();

		changingDevices = false
		return true
	}

	function resumeContext() {
		if (audioContext.state === "suspended") {
			audioContext.resume();
		}
	}

	async function updateInputStream(deviceId) {
		if (currentSource) {
			currentSource.disconnect()
		}

		if (inputStream) {
			for (const track of inputStream.getAudioTracks()) {
				track.stop()
			}
		}

		if (deviceId) {
			inputStream = await navigator.mediaDevices.getUserMedia({audio: {deviceId}})
			currentSource = audioContext.createMediaStreamSource(inputStream)
			currentSource.connect(analyser)
			resumeContext()
		}
	}

	function updateAudioElement(deviceId) {
		if(audioElement.setSinkId) {
			audioElement.setSinkId(deviceId)
		}
	}

	function getAnalyserLevel() {
		try {
			analyser.getByteFrequencyData(analyserData)
			const arraySum = analyserData.reduce((a, value) => a + value, 0)
			return arraySum / analyserData.length
		} catch (error) {
			// eslint-disable-next-line
			console.warn('>>> get analyser level', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
			return 0
		}
	}

	function playTestSound() {
		try {
			audioElement.play()
		} catch (error) {
			// eslint-disable-next-line
			console.warn('>>> play test sound', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	function disconnect() {
		if (currentSource) {
			currentSource.disconnect()
		}
		if (inputStream) {
			for (const track of inputStream.getAudioTracks()) {
				track.stop()
			}
		}
		currentSource = null
		inputStream = null
	}

	async function stopTracks(video) {
		if (video?.srcObject) {
			video.srcObject.getVideoTracks().forEach((track) => track.stop());
			video.srcObject = null;
		}
		await navigator.mediaDevices
			.getUserMedia({audio: true, video: true})
			.then((stream) => {
				stream.getTracks().forEach(function (track) {
					track.stop();
				})
			})
		await updateInputStream();
	}

	const devices = {
		deviceChangedHandler,
		updateInputStream,
		getAnalyserLevel,
		disconnect,
		playTestSound,
		updateAudioElement,
		audioOutputChangeHandler,
		audioInputChangeHandler,
		cameraChangeHandler,
		stopTracks,
		getDeviceAccess,
	}

	inject('devices', {...devices})

	return {...devices}
}
